import React from 'react';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Typography from '@mui/material/Typography';
import { checkAuth } from "../../util/Auth";
import reqClient from "../../util/reqClient";
import { CUSTOMERS } from '../../constants/paths';
import { json, useLoaderData } from "react-router-dom";
import { setToast } from '../../states/dashboard';
import BackBtn from '../../components/UI/BackBtn';

import Details from '../../components/customers/Details';

const CustomerDetails = () => {
  const data = useLoaderData();
  
  return (
    <Row className="g-0">
      <Col>
        <div className='d-flex justify-content-between mb-3'>
          <Typography 
            variant="h4"
            sx={{ fontSize: '2rem', fontWeight: 500 }}
            >Customer Details</Typography>
          <BackBtn size="small" text="Back to list" />
        </div>
        
        <Details data={data} />
      </Col>
    </Row>
  )
}

export default CustomerDetails;

export const loader = async ({request, params}) => {
  if (!checkAuth()) { return null; }

  console.log('INSIDE LOADER', params.cId);

  try {
    const response = await reqClient('admin')
      .get(`/${CUSTOMERS}/${params.cId}`);

    const resData = response.data;

    if (resData.type === 'success') {
      return resData.data;
    }
    else {
      setToast("error", 
        resData.message ? resData.message : "Something went wrong! Please try again later.",
        true
      );
    }
  }
  catch(error) {
    let errMessage = "Unexpected error!";
    if(error.response?.data?.message) {
      errMessage = error.response.data.message;
    }

    throw json(
      {
        message: errMessage,
        role: 'admin'
      },
      { status: (error.response?.status || 500) }
    );
  }

  return null;
}
