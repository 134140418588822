import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Form as ReactRouterForm, useActionData, redirect, Link } from "react-router-dom";
import Divider from "../../components/divider/Divider";
import LinkedinSVG from "../../assets/icons/linkedin.svg";
import RightHeader from "../../components/auth/RightHeader";
import MainHeading from "../../components/auth/MainHeading";
import classes from "./Login.module.scss";
import axios from "axios";
import { setAccessToken } from '../../util/Auth';
import { setToast } from '../../states/dashboard';
import { getCurrentUser } from '../../util/Auth';
import currentUserState from "../../states/currentUser";
import { FORGOT_PWD, DASHBOARD } from "../../constants/paths";

import { useTranslation } from "react-i18next";

export default function Login() {
  const { t } = useTranslation();

  const actionResponse = useActionData();
  const data = actionResponse?.data;

  return (
    <>
      <RightHeader
        title={t('don\'t have and account')}
        btn={t('signup')}
        url={"/signup"}
      />
      <MainHeading title={t('welcome back')} />
      <Row md={12}>
        <Col className={classes.formWrapper}>
          <ReactRouterForm method='post' noValidate>
            <Form.Group className="mb-4" controlId="email">
              <Form.Label>{t('email address')}</Form.Label>
              <Form.Control 
                name="email" 
                type="email" 
                placeholder={t('email address')}
                isInvalid={data && data.errors?.email}
              />
              {data && data.errors?.email &&
                <Form.Control.Feedback type="invalid">
                  {data.errors?.email}
                </Form.Control.Feedback>
              }
            </Form.Group>

            <Form.Group className="mb-4" controlId="password">
              <Form.Label >{t('password')}</Form.Label>
              <Form.Control 
                name="password" 
                type="password"
                placeholder={t('password')}
                isInvalid={data && data.errors?.password}
              />
              {data && data.errors?.password &&
                <Form.Control.Feedback type="invalid">
                  {data.errors?.password}
                </Form.Control.Feedback>
              }
            </Form.Group>

            <Row>
              <Col xs={6} sm={6} md={6} className={classes.rememberMe}>
                {/* <Form.Check
                  type="checkbox"
                  label={t('remember me')}
                  className={classes.subLabels}
                  name="rememberMe"
                  id="rememberMe"
                /> */}
              </Col>
              <Col xs={6} sm={6} md={6} className={classes.forgotPwd}>
                <p className={classes.subLabels}><Link to={`/${FORGOT_PWD}`}>{t('forgot password')}</Link></p>
              </Col>
            </Row>

            <div className="d-grid gap-2 mt-4">
              <Button variant="primary" className={`themeBtnFilled`} type="submit">
              {t('login')}
              </Button>
            </div>

            {/* <Divider text="Or" /> */}

            {/* <div className="d-grid gap-2 mt-4">
              <Button className={classes.signupLinkedinBtn} type="button">
                <img src={LinkedinSVG} alt="LinkedIn" /> {t('signin with linkedin')}
              </Button>
            </div> */}
          </ReactRouterForm>
        </Col>
      </Row>
    </>
  );
};

const action = async ({request, params}) => {
  const data = await request.formData();

  // reset the toaster
  //setToast("success", "", false);

  const postData = {
    email: data.get('email'),
    password: data.get('password')
  }

  try {
    const response = await axios({
      url: `${process.env.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_API_USER_PREFIX}/login`,
      method: 'post',
      data: postData
    });

    const resData = response.data;
    if (resData && resData.data?.accessToken) {

      let user = await getCurrentUser(resData.data.accessToken);
      if (!user) {
        setToast("error", "Error fetching user details. Please try again later.", true);
        return null;
      }

      user.role = resData.data.role;
      currentUserState.user = user;
      // finally set the access token
      setAccessToken(resData.data?.accessToken);
      return redirect(`/${DASHBOARD}`);
    }

    setToast("error", "Unexpected error. Please try again later.", true);
    return null;
  }
  catch (error) {
    if ((error.response?.status == 422)) {
      return error.response;
    }
    
    let errMessage = "Unexpected error!";
    if(error.response.data?.message) {
      errMessage = error.response.data?.message;
    }

    setToast("error", errMessage, true);

    return null;
  }
};

export { action };