import { useState } from "react";
import classes from "./ProfileForm.module.scss";
import {
  Badge,
  Avatar,
  imageListClasses,
  Stack,
  Divider,
  useMediaQuery,
} from "@mui/material";
import avatarImg from "../../assets/images/big_logo.jpg";
import penImg from "../../assets/icons/Pen.svg";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import { BsEye } from "react-icons/bs";
import { BsEyeSlash } from "react-icons/bs";
import ThemeBtn from "../UI/ThemeBtn";
import { Form as RouterForm, useActionData } from 'react-router-dom';
import { useTranslation } from "react-i18next";

const ProfileForm = (props) => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const isSmallerScreen = useMediaQuery("(max-width:992px)");
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const user = props.user;
  const formErrors = useActionData();
  const { t } = useTranslation();

  const inputErrMsg = (message) => {
    return (
      <Form.Control.Feedback type="invalid">
        {message}
      </Form.Control.Feedback>
    );
  }

  return (
    <div className={classes.wrapper}>
      <h2 className={classes.title}>{t('My Profile')}</h2>
      {/* <div className={classes.avatarWrapper}>
        <img className={classes.avatar} src={avatarImg} alt="avatarImg" />
        <div className={classes.avatarBtnWrapper}>
          <a href="#">
            <img className={classes.avatarBtn} src={penImg} alt="penImg" />
          </a>
        </div>
      </div> */}
      <RouterForm method="put">
        <div className={classes.gridContainer}>
          <input type="hidden" name="formType" value="profile" />
          <Form.Group className="mb-3" >
            <Form.Label>{t('First name', { ns: 'glossary' })}</Form.Label>
            <Form.Control 
              type="text"
              placeholder={t('First name', { ns: 'glossary' })}
              name="firstName"
              defaultValue={user.first_name}
              isInvalid={formErrors && formErrors.errors?.firstName}
            />
            { formErrors && formErrors.errors?.firstName && 
              inputErrMsg(formErrors.errors?.firstName)
            }
          </Form.Group>
          <Form.Group className="mb-3" >
            <Form.Label>{t('Last name', { ns: 'glossary' })}</Form.Label>
            <Form.Control
              type="text"
              placeholder={t('Last name', { ns: 'glossary' })}
              name="lastName"
              defaultValue={user.last_name}
              isInvalid={formErrors && formErrors.errors?.lastName}
            />
            { formErrors && formErrors.errors?.lastName && 
              inputErrMsg(formErrors.errors?.lastName)
            }
          </Form.Group>
          <Form.Group className="mb-3" >
            <Form.Label>{t('glossary:Email')} </Form.Label>
            <Form.Control 
              type="email" 
              placeholder="name@example.com" 
              name="email" 
              defaultValue={user.email}
              isInvalid={formErrors && formErrors.errors?.email}
            />
            { formErrors && formErrors.errors?.email && 
              inputErrMsg(formErrors.errors?.email)
            }
          </Form.Group>
          <Form.Group className="mb-3" >
            <Form.Label>{t('glossary:Phone')}</Form.Label>
            <Form.Control 
              type="text"
              placeholder="+xxxxxxxxxxxx"
              name="phone"
              defaultValue={user.phone}
              isInvalid={formErrors && formErrors.errors?.phone}
            />
            { formErrors && formErrors.errors?.phone && 
              inputErrMsg(formErrors.errors?.phone)
            }
          </Form.Group>
          {/* <Form.Group className="mb-4" >
            <Form.Label>Password</Form.Label>
            <InputGroup>
              <Form.Control
                type={passwordVisible ? "text" : "password"}
                placeholder="Password"
                style={{ backgroundColor: "white", borderRight: "none" }}
              />
              <InputGroup.Text
                id="basic-addon1"
                style={{ backgroundColor: "white" }}
                className="pr-5"
                onClick={togglePasswordVisibility}
              >
                {passwordVisible ? <BsEye /> : <BsEyeSlash />}
              </InputGroup.Text>
            </InputGroup>
          </Form.Group> */}
        </div>
        <div className={`${classes.btnWrapper} mt-4 mb-3`}>
          <ThemeBtn text={`Save`} variant="primary" type="submit"/>
        </div>
      </RouterForm>
    </div>
  )
}

export default ProfileForm;