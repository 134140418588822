import React from 'react'
import classes from './SelectPlan.module.scss';
import checkMarck from "../../assets/icons/check-circle-1.svg";
import checkMarckWhite from "../../assets/icons/check-circle-white.svg";
import ThemeBtn from '../UI/ThemeBtn';
import { useTranslation } from "react-i18next";

export default function SelectPlan(props) {
  const plans = props.plans;
  const defaultId = props.defaultId

  const planSelectHandler = (planId) => {
    //console.log('planSelectHandler', planId);
    props.onSelect(planId);
  }
  
  return (
    <div className={classes.wrapper}>
      <div className={classes.list}>
        {plans.map((plan, index) => (
          <PlanItem 
            plan={plan}
            isActive={plan.id === defaultId}
            key={plan.id}
            handleSelect={planSelectHandler}
          />
        ))}
      </div>
    </div>
  )
}

export const PlanItem = (props) => {
  const { t } = useTranslation();
  const plan = props.plan;
  const isActive = props.isActive;

  const handleSelect = (e) => {
    props.handleSelect(plan.id);
  }

  return (
    <div className={`${classes.planItem} ${isActive ? classes.active : ''}`}>
      <p>
        <span className={classes.price}>{`${plan.currency} ${plan.price}`}</span>
        <span className={classes.pricePeriod}>/month</span>
      </p>
      <h3 className={classes.planTitle}>
        {plan.title}
      </h3>

      {plan.features.map((item, index) => (
        <div key={index} className={classes.preferences}>
          <img
            className={classes.img}
            src={isActive ? checkMarckWhite : checkMarck}
            alt="-"
          />
          <p className={classes.descriptionListItem}>
            {item}
          </p>
        </div>
      ))}

      {
        !isActive &&
        <ThemeBtn 
          text={t('choose plan')} 
          className={classes.btn} 
          disabled={false}
          onClick={handleSelect}
        />
      }

      {
        isActive &&
        <ThemeBtn 
          text={t('selected')} 
          className={classes.btn} 
          disabled={true}
        />
      }
    </div>
  )
}
