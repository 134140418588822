import React from 'react';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from 'react-bootstrap/Form';
import { useActionData } from "react-router-dom";

const JobAppForm = (props) => {
  const data = props.data;

  const actionData = useActionData();

  const errors = actionData?.data?.errors;

  return (
    <>
      <Row>
        <Col lg={6}>
          <Form.Group className="mb-3" controlId="company">
            <Form.Label>Company Name</Form.Label>
            <Form.Control 
              type="text" 
              name="company"
              defaultValue={(data?.company || '')}
              isInvalid={errors && errors?.company}
            />
            {errors && errors?.company &&
              <Form.Control.Feedback type="invalid">
                {errors?.company}
              </Form.Control.Feedback>
            }
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mb-3" controlId="jobPosition">
            <Form.Label>Position</Form.Label>
            <Form.Control 
              type="text" 
              name="jobPosition"
              defaultValue={(data?.position || '')}
              isInvalid={errors && errors?.jobPosition}
            />
            {errors && errors?.jobPosition &&
              <Form.Control.Feedback type="invalid">
                {errors?.jobPosition}
              </Form.Control.Feedback>
            }
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="mb-3" controlId="link">
            <Form.Label>Link</Form.Label>
            <Form.Control 
              type="text" 
              name="link"
              defaultValue={(data?.link || '')} 
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="mb-3" controlId="description">
            <Form.Label>Description</Form.Label>
            <Form.Control 
              as="textarea" 
              rows={3} 
              name="description"
              defaultValue={(data?.description || '')}
            />
          </Form.Group>
        </Col>
      </Row>
    </>
  )
}

export default JobAppForm;