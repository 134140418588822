import * as PATHS from '../constants/paths';

const ADMIN_MENU = {
  dashboard: {
    title: 'Dashboard',
    path: `/${PATHS.ADMIN}`
  },
  customers: {
    title: 'Customers',
    path: `/${PATHS.ADMIN}/${PATHS.CUSTOMERS}`
  },
  jobApplications: {
    title: 'Jobs',
    path: `/${PATHS.ADMIN}/${PATHS.JOBS}`
  },
  /*
  profile: {
    title: 'My Profile',
    path: `/${PATHS.ADMIN}/${PATHS.PROFILE}`
  }*/
};

const USER_MENU = {
  dashboard: {
    title: 'Dashboard',
    path: `/${PATHS.DASHBOARD}`
  },
  profile: {
    title: 'My Profile',
    path: `/${PATHS.PROFILE}`
  },
  appliedJobs: {
    title: 'My applied jobs',
    path: `/${PATHS.APPLIED_JOBS}`
  }
}

export const getMenuItems = (role = 'customer', menuType = 'main_menu') => {
  let result;

  if (role === 'customer') {
    // currently all menuTypes of user have the same list items
    result = USER_MENU;
  }
  else if (role === 'admin') {
    switch (menuType) {
      case 'nav_dd':
        result = {
          profile: ADMIN_MENU.profile
        }
        break;
      default:
        result = ADMIN_MENU
    }
  }

  return result;
}