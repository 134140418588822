import React from 'react';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

const ThemePagination = (props) => {
  const page = props.page ? parseInt(props.page) : 1
  return (
    <Stack spacing={(props.spacing || 2)}>
      <Pagination 
        count={(props.count || 10)} 
        shape="rounded" 
        color="primary"
        onChange={props.onChange}
        page={page} 
      />
    </Stack>
  )
}

export default ThemePagination;
