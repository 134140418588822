import React from 'react';
import DetailsForm from "../../components/jobDetails/DetailsForm";
import reqClient from "../../util/reqClient";
import { JOB_DETAILS } from "../../constants/paths";
import { setToast } from '../../states/dashboard';
import { redirect, useLoaderData } from 'react-router-dom';
import { checkAuth } from "../../util/Auth";
import { jdSteps } from '../../states/dashboard';

export default function EditJobDetails() {
  const data = useLoaderData();

  jdSteps.active = 0;

  return (
    <DetailsForm 
      data={data} 
      method="put"
    />
  )
}

export const loader = async ({ params }) => {
  if (!checkAuth()) { return null; }

  // reset the toaster
  //setToast("success", "", false);

  try {
    const response = await reqClient()
      .get(`/${JOB_DETAILS}/${params.jdId}`);

    const resData = response.data;

    if (resData?.data) {
      return resData.data;
    }
    else {
      setToast("error", 
        resData.message ? resData.message : "Something went wrong! Please try again later.",
        true
      );
      return null;
    }
  }
  catch(error) {
    let errMessage = "Unexpected error!";
    if(error.response?.data?.message) {
      errMessage = error.response.data.message;
    }

    setToast("error", errMessage, true);
    return null;
  }
}