import CircularProgress from '@mui/material/CircularProgress';

const ThemeCircularStat = (props) => {
  return (
    <div className="d-flex flex-column justify-content-center align-items-center">
      <div className="d-flex flex-column justify-content-center align-items-center">
        <CircularProgress 
          variant="determinate" 
          value={100} 
          thickness={5}
          size={102}
          sx={{
            color: 'var(--bs-light-primary)'
          }}
        />
        <p style={{
          fontSize: '32px',
          fontWeight: '600',
          marginTop: '-72px',
          marginBottom: '38px',
          color: 'var(--bs-light-primary-text)'
        }}>{props.count}</p>
      </div>
      <p className="dashHelperText text-center" style={{opacity: 1}}>{props.label}</p>
    </div>
  )
}

export default ThemeCircularStat;