import { useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import classes from './DynamicAutoComplete.module.scss';

const DynamicAutoComplete = (props) => {
  const sectionHead = props.sectionHead || '';
  const getOptionLabel = props.getOptionLabel ? props.getOptionLabel : ((option) => option.name);
  const defaultValue = props.defaultValue || [];

  const [show, setShow] = useState(defaultValue.length > 0 ? true : false);
  const [itemIds, setItemIds] = useState(defaultValue);

  return (
    <div className="d-flex flex-column mb-3">
      <h5 className="dashSectionSubHead mt-2 mb-3">{sectionHead}</h5>
      { show &&
      <div className="d-flex flex-column mb-3">
        <div className="d-flex justify-content-between">
          <Autocomplete
            className={classes.autoComplete}
            multiple
            options={props.options}
            getOptionLabel={getOptionLabel}
            isOptionEqualToValue={ (option, value) => option.id === value.id }
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                placeholder="Select"
              />
            )}
            defaultValue={defaultValue}
            onChange={ (event, value, reason) => { setItemIds(value) } }
          />
          <button className={classes.delBtn} type="button" onClick={ () => { setShow(false); } }>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M6.525 21C6.1125 21 5.75938 20.8531 5.46563 20.5594C5.17188 20.2656 5.025 19.9125 5.025 19.5V5.25H4V3.75H8.7V3H15.3V3.75H20V5.25H18.975V19.5C18.975 19.9 18.825 20.25 18.525 20.55C18.225 20.85 17.875 21 17.475 21H6.525ZM17.475 5.25H6.525V19.5H17.475V5.25ZM9.175 17.35H10.675V7.375H9.175V17.35ZM13.325 17.35H14.825V7.375H13.325V17.35Z" fill="#061645" fillOpacity="0.5"/>
            </svg>
          </button>

          {
            itemIds.map((item) => (
              <input key={item.id} type="hidden" name={props.name} value={item.id} />
            ))
          }
        </div>
      </div>
      }
      { !show &&
      <button type="button" onClick={() => {setShow(true)}} className="themeAddMoreBtn">+ {props.btnLabel ? props.btnLabel : 'Add More'}</button>
      }
    </div>
  )
}

export default DynamicAutoComplete;