import React from 'react';
import { FaRegFileAlt, FaRegFilePdf, FaRegFileImage } from "react-icons/fa";

const ThemeFileIcon = (props) => {
  const fileName = props.fileName;

  let iconStyle = {
    margin: '0 2px',
    width: '35px',
    height: 'auto',
    color: '#E2574C'
  };

  if (props.style) {
    iconStyle = {
      ...iconStyle,
      ...props.style
    }
  }

  let fileIcon = <FaRegFileAlt style={iconStyle} />

  if (fileName) {
    let ext = fileName.split('.').pop();
    if (ext.toLowerCase() === 'pdf') {
      fileIcon = <FaRegFilePdf style={iconStyle} />;
    }
    else if(['jpg', 'jpeg', 'png'].includes(ext.toLowerCase())) {
      fileIcon = <FaRegFileImage style={iconStyle} />;
    }
  }

  return (
    fileIcon
  )
}

export default ThemeFileIcon