import React from 'react'
import ProfileForm from '../../components/profile/ProfileForm';
import ThemeBox from '../../components/UI/ThemeBox';
import { checkAuth } from "../../util/Auth";
import reqClient from "../../util/reqClient";
import { setToast } from '../../states/dashboard';
import { PROFILE } from "../../constants/paths";
import { useLoaderData } from "react-router-dom";
import currentUserState from "../../states/currentUser";
import ChangePassword from '../../components/profile/ChangePassword';
import ManagePlan from '../../components/profile/ManagePlan';

const Profile = () => {
  const user = useLoaderData();

  return (
    <>
      <ThemeBox>
        { user && <ProfileForm user={user}></ProfileForm> }
      </ThemeBox>
      <ThemeBox>
        { user && <ChangePassword /> }
      </ThemeBox>
      <ManagePlan />
    </>
  )
}

export default Profile;

export async function loader() {
  if (!checkAuth()) { return null; }

  try {
    const response = await reqClient()
      .get(`/${PROFILE}`, {
        params: {
          items: [
            'first_name', 'last_name', 'email', 'phone'
          ]
        }
      });

    const resData = response.data;
    
    if (resData.type === 'success') {
      return resData.data.user;
    }
    else {
      setToast("error", 
        resData.message ? resData.message : "Something went wrong! Please try again later.",
        true
      );
    }
  }
  catch(error) {
    setToast("error", "Something went wrong! Please try again later.", true);
  }

  return null;
}

export const action = async ({request, params}) => {
  const formData = await request.formData();
  const formType = formData.get('formType');
  let postData;
  let response;
  
  try {
    if (formType === 'profile') {
      postData = {
        firstName: formData.get('firstName'),
        lastName: formData.get('lastName'),
        email: formData.get('email'),
        phone: formData.get('phone')
      }

      response = await reqClient()
        .put(`/${PROFILE}`, postData);

      if(response.data.status === 'failed') {
        setToast(
          "error", 
          response.data.message ? response.data.message : "An error occurred. Please try again later.",
          true
        );
      }

      let currentRole = currentUserState.user.role;
      let currentUser = response.data.data.user;
      currentUser.role = currentRole;

      currentUserState.user = currentUser;
      setToast("success", "Successfully updated!", true);
    }
    if (formType === 'changePassword') {
      postData = {
        oldPassword: formData.get('oldPassword'),
        newPassword: formData.get('newPassword'),
        confirmPassword: formData.get('confirmPassword')
      }

      response = await reqClient()
        .put(`/password`, postData);

      if(response.data.status === 'failed') {
        setToast(
          "error", 
          response.data.message ? response.data.message : "An error occurred. Please try again later.",
          true
        );
      }

      setToast("success", "Successfully updated!", true);

      return response.data;
    }
  }
  catch (error) {
    if ((error.response?.status == 422)) {
      return error.response.data;
    }

    let errMessage = "Unexpected error!";
    if(error.response.data?.message) {
      errMessage = error.response.data?.message;
    }

    setToast("error", errMessage, true);
  }

  return null;
}

