import { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import classes from './Root.module.scss';

import LogoDark from '../../assets/logo-dark.svg'
import { MdMenu } from "react-icons/md";
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Link, useNavigate, ScrollRestoration } from 'react-router-dom';

import ThemeBtn from '../../components/UI/ThemeBtn';
import { Outlet } from "react-router-dom";
import Toast from "../../components/UI/toast";
import FooterBg from '../../assets/icons/FooterBg.svg';
import { useTranslation } from "react-i18next";

import { LOGIN, REGISTER, DASHBOARD } from '../../constants/paths';
import { isLoggedIn } from '../../util/Auth';

const RootLayout = () => {
  const [showMenu, setShowMenu] = useState(false);

  const handleMenuClose = () => setShowMenu(false);
  const handleMenuShow = () => setShowMenu(true);

  const { t } = useTranslation();
  const navigate = useNavigate();

  const goToLogin = () => {
    navigate(`/${LOGIN}`);
  }

  const goToRegister = () => {
    navigate(`/${REGISTER}`);
  }

  const goToDashboard = () => {
    navigate(`/${DASHBOARD}`);
  }

  return (
    <Container fluid className="g-0">
      <Row className={`g-0 ${classes.header}`}>
        <Col lg={1} className="my-4 d-none d-lg-block"></Col>
        <Col lg={6} md={6} xs className="my-4">
          <Link to="/"><img src={LogoDark} className={`${classes.logo} ms-4`} /></Link>
        </Col>
        <Col lg={4} md={6} xs className="my-4">
          <div className={`float-end me-4 ${classes.btnContainer}`}>
            { !isLoggedIn() && 
            <>
              <ThemeBtn text={t('login')} className="me-2" onClick={goToLogin} />
              <ThemeBtn text={t('glossary:Register')} variant="primary" onClick={goToRegister} />
            </>
            }
            { isLoggedIn() && 
              <ThemeBtn text={t('My Account')} className="me-2" onClick={goToDashboard} />
            }
          </div>
          <div className={`float-end me-4 ${classes.menuIconContainer}`}>
            <button><MdMenu className={classes.menuIcon} onClick={handleMenuShow} /></button>
          </div>
        </Col>
        <Col lg={1} className="my-4 d-none d-lg-block"></Col>

        <Offcanvas show={showMenu} onHide={handleMenuClose} placement="top">
          <Offcanvas.Header closeButton className={classes.mainMenuHead}>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div className={classes.mainMenuItemWrapper}>
              { !isLoggedIn() && 
              <>
                <div><Link onClick={handleMenuClose} to={`/${LOGIN}`} >{t('login')}</Link></div>
                <div><Link onClick={handleMenuClose} to={`/${REGISTER}`} >{t('glossary:Register')}</Link></div>
              </>
              }
              { isLoggedIn() && 
                <div><Link onClick={handleMenuClose} to={`/${DASHBOARD}`} >{t('My Account')}</Link></div>
              }
            </div>
          </Offcanvas.Body>
        </Offcanvas>
      </Row>

      <Outlet />

      <div className={classes.footer}>
        <div className={classes.footerTop} style={{ 
            backgroundImage: `url(${FooterBg})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat'
          }}>
          <h2>{t('Ready to Step Into the Future of Job Hunting?', {'ns': 'home'})}</h2>
          <p>{t('Let us manage your job search, while you focus on preparing for your next big career move.', {'ns': 'home'})}</p>
          <ThemeBtn text={t('Let\'s Start', {'ns': 'home'})} variant="primary" onClick={goToRegister} />
        </div>
        <div className={classes.footerBottom}>
          <Row className='g-0'>
            <Col lg={1}></Col>
            <Col lg={10}>
              <div className={classes.ftLinksContainer}>
                <div className={classes.ftLogoContainer}>
                  <Link to="/"><img src={LogoDark} className={`${classes.ftLogo}`} /></Link>
                </div>
                <Link className={classes.ftLink} to='/terms-of-service'>{t('Terms of service')}</Link>
                <Link className={classes.ftLink} to='/privacy-policy'>{t('Privacy policy')}</Link>
                <Link className={classes.ftLink} to={`mailto:${process.env.REACT_APP_CONTACT_US_EMAIL}`}>{t('Contact us')}</Link>
              </div>
            </Col>
            <Col lg={1}></Col>
          </Row>
        </div>
      </div>
      <Toast />
      <ScrollRestoration />
    </Container>
  )
}

export default RootLayout;