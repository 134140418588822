import React, { useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { Form, InputGroup } from "react-bootstrap";
import RightHeader from "../../components/auth/RightHeader";
import MainHeading from "../../components/auth/MainHeading";
import classes from "./Register.module.scss";
import { Form as ReactRouterForm, useActionData, redirect, useSearchParams } from "react-router-dom";
import { setToast } from '../../states/dashboard';
import { useTranslation } from "react-i18next";
import { getQueryParams } from "../../util/Helper";
import reqClient from "../../util/reqClient";

export default function ForgotPassword() {
  const { t } = useTranslation();
  const actionResponse = useActionData();
  const data = actionResponse?.data;

  const [searchParams] = useSearchParams();
  console.log('searchParams', searchParams);

  let loginLink = "/login";
  if (searchParams.get('type') === 'admin') {
    loginLink = "/admin/login";
  }

  return (
    <>
      <RightHeader
        title={t("Already have an account")}
        btn={t("login")}
        url={loginLink}
      />
      <MainHeading title={t("Forgot password")} />
      <Row md={12}>
        <Col className={classes.formWrapper}>
          <ReactRouterForm method='post' noValidate>
            <Form.Group className="mb-4" controlId="email">
              <Form.Label>{t("email address")}</Form.Label>
              <Form.Control 
                type="email"
                name="email"
                placeholder="j.roi@mail.com" 
                isInvalid={data && data.errors?.email}
              />
              {data && data.errors?.email &&
                <Form.Control.Feedback type="invalid">
                  {data.errors?.email}
                </Form.Control.Feedback>
              }
            </Form.Group>
            <div className="d-grid gap-2 mt-5">
              <Button variant="primary" className={`themeBtnFilled`} type="submit">
                { t("Submit") }
              </Button>
            </div>
          </ReactRouterForm>
        </Col>
      </Row>
    </>
  );
}

const action = async ({request, params}) => {
  const data = await request.formData();

  const queryParams = getQueryParams(request.url);
  let role;

  if (queryParams?.type === 'admin') {
    role = 'admin';
  }
  else {
    role = 'customer'
  }

  const postData = {
    email: data.get('email')
  }

  try {

    const response = await reqClient(role).post(
      `/create-password-reset`,
      postData
    );

    const resData = response.data;
    if (resData?.type === 'success') {
      setToast("success", resData.message, true);
      return true;
    }
    else {
      setToast("error", "Something went wrong. Please try again later.", true);
    }
  }
  catch (error) {
    if ((error.response?.status == 422)) {
      return error.response;
    }
    
    let errMessage = "Unexpected error!";
    if(error.response.data?.message) {
      errMessage = error.response.data?.message;
    }

    setToast("error", errMessage, true);
  }

  return null;
}

export { action };
