import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import classes from './PolicyPages.module.scss';
import Divider from '../../components/divider/Divider';

const PrivacyPolicy = () => {
  return (
    <Row className='g-0'>
      <Col lg={1}></Col>
      <Col lg={10}>
        <div className={`${classes.tsWrapper} mb-4`}>
          <Divider className='mt-0' />
          <h1 className='themeH1'>JobApply24 Privacy Policy</h1>
          <p className={`${classes.para} my-3`}>Effective Date: August 15th 2023</p>
          <p className={`${classes.para} my-3`}>At JobApply24, your privacy is paramount. This Privacy Policy outlines the types of information we collect, 
            how we use it, and the measures we take to ensure your data remains private and secure.</p>

          <p className='dashSectionHead my-3'>1. Information We Collect</p>
          <ul className='my-3 ms-2'>
            <li className={`${classes.listItem} mb-2`}><b>Personal Data:</b> This includes but is not limited to your name, email address, phone number, and resume details. 
              This data is provided voluntarily by you during the registration process or when using our services.</li>
            <li className={`${classes.listItem} mb-2`}><b>Usage Data:</b> We automatically collect data when you access our platform, such as your IP address, browser type, and pages visited.</li>
          </ul>

          <p className='dashSectionHead my-3'>2. How We Use Your Information</p>
          <ul className='my-3 ms-2'>
            <li className={`${classes.listItem} mb-2`}>To provide, maintain, and improve our services.</li>
            <li className={`${classes.listItem} mb-2`}>To process and communicate with you about job applications.</li>
            <li className={`${classes.listItem} mb-2`}>To respond to your queries and support requests.</li>
            <li className={`${classes.listItem} mb-2`}>For research and analytics to optimize our platform.</li>
            <li className={`${classes.listItem} mb-2`}>To communicate important notices and updates.</li>
          </ul>

          <p className='dashSectionHead my-3'>3. Data Protection Rights Under GDPR</p>
          <ul className='my-3 ms-2'>
            <li className={`${classes.listItem} mb-2`}>You have the right to access, correct, or delete your personal data. </li>
            <li className={`${classes.listItem} mb-2`}>You have the right to object to our processing of your personal data and have the right to portability of the data.</li>
            <li className={`${classes.listItem} mb-2`}>You have the right to withdraw consent if you have previously given it.</li>
          </ul>

          <p className='dashSectionHead my-3'>4. Sharing of Data</p>
          <ul className='my-3 ms-2'>
            <li className={`${classes.listItem} mb-2`}>We do not sell, rent, or trade your personal information to third parties. </li>
            <li className={`${classes.listItem} mb-2`}>We may share data with trusted third parties to perform functions on our behalf (e.g., hosting services, analytics), but these parties are bound by confidentiality agreements.</li>
          </ul>

          <p className='dashSectionHead my-3'>5. Security</p>
          <ul className='my-3 ms-2'>
            <li className={`${classes.listItem} mb-2`}>We employ industry-standard measures to protect the data you provide to us from unauthorized access or disclosure. </li>
          </ul>

          <p className='dashSectionHead my-3'>6. Cookies</p>
          <ul className='my-3 ms-2'>
            <li className={`${classes.listItem} mb-2`}>Our platform uses "cookies" to enhance user experience. You have the option to refuse cookies, 
              but it may prevent you from using certain parts of the platform. </li>
          </ul>

          <p className='dashSectionHead my-3'>7. Third-Party Links</p>
          <ul className='my-3 ms-2'>
            <li className={`${classes.listItem} mb-2`}>Our platform may contain links to other websites. We are not responsible for the privacy practices of those sites. </li>
          </ul>

          <p className='dashSectionHead my-3'>8. Children’s Privacy</p>
          <ul className='my-3 ms-2'>
            <li className={`${classes.listItem} mb-2`}>Our service is not intended for anyone under the age of 18. We do not knowingly collect personal data from individuals under 18. </li>
          </ul>

          <p className='dashSectionHead my-3'>9. Changes to This Policy</p>
          <ul className='my-3 ms-2'>
            <li className={`${classes.listItem} mb-2`}>We reserve the right to modify this Privacy Policy at any time. We will always post the most current version on our platform.</li>
          </ul>

          <p className='dashSectionHead my-3'>10. Contacting Us</p>
          <ul className='my-3 ms-2'>
            <li className={`${classes.listItem} mb-2`}>For questions related to this Privacy Policy, please contact us at support@jobapply24.com</li>
          </ul>

          <p className={`${classes.para} my-3`}>By using our platform, you consent to the data practices outlined in this policy. 
            If you do not agree with any aspect of this Privacy Policy, please discontinue use of our platform.</p>
        </div>
      </Col>
      <Col lg={1}></Col>
    </Row>
  )
}

export default PrivacyPolicy;
