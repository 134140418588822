import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    ns: [
      'common', 
      // 'validation',
      'glossary',
      'home'
    ],
    defaultNS: 'common',
    backend: {
      crossDomain: true,
      loadPath: `${process.env.REACT_APP_LOCALE_CDN_END_POINT}/{{lng}}/{{ns}}.json`,
      queryStringParams: { v: process.env.REACT_APP_LOCALE_URL_VERSION },
    }
  });

export default i18n;
