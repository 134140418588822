import React, { useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import Offcanvas from "react-bootstrap/Offcanvas";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import classes from "./Header.module.scss";
import Logo from "../../assets/logo-light.svg";
import avatar from "../../assets/images/avatar_default.svg";
import { BiMenu } from "react-icons/bi";
import { Link, useSubmit } from "react-router-dom";
import { useSnapshot } from "valtio";
import currentUserState from "../../states/currentUser";
import { useTranslation } from "react-i18next";
import { DASHBOARD } from "../../constants/paths";
import { getMenuItems } from "../../util/MenuItems";

export default function Header() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const submit = useSubmit();
  const { t } = useTranslation();

  const { user: currentUser } = useSnapshot(currentUserState);

  const handleLogout = () => {
    submit(null, {
      action: "/logout",
      method: "post"
    });
  }

  const navddMenu = getMenuItems(currentUser.role, 'nav_dd');
  const mainMenu = getMenuItems(currentUser.role);

  return (
    <div className={classes.container}>
      <Link to={`/${DASHBOARD}`}><img className={classes.logo} src={Logo} alt="logo" /></Link>
      <div className={classes.menuBig}>
        <Image src={avatar} roundedCircle className={classes.avatar} />
        <Dropdown className={classes.dropdown}>
          <Dropdown.Toggle
            className={classes.menu}
            variant="success"
            id="dropdown-basic"
          >
            {`${currentUser?.first_name} ${currentUser?.last_name || ''}`}
          </Dropdown.Toggle>

          <Dropdown.Menu style={{ marginTop: 25, width: 212 }}>
            {
              Object.keys(navddMenu).map((key) => {
                return <Link to={navddMenu[key].path} className={`dropdown-item`} key={`ndd_${key}`}>{t(navddMenu[key].title)}</Link>
              })
            }
            <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>

      <div className={classes.menuSmall}>
        <Button variant="outline-primary" onClick={handleShow} className="me-2">
          <BiMenu />
        </Button>
        <Offcanvas
          show={show}
          onHide={handleClose}
          placement={"end"}
          style={{ padding: 0 }}
          // className={classes.canvas}
        >
          <Offcanvas.Header closeButton>
          </Offcanvas.Header>
          <Offcanvas.Body>
            {
              Object.keys(mainMenu).map((key) => {
                return <Link to={mainMenu[key].path} 
                  className={classes.offCanvasMenuLink} 
                  key={`mm_${key}`}
                  onClick={() => { handleClose(); return true; }}
                >{t(mainMenu[key].title)}</Link>
              })
            }
            <Button variant="light" style={{ width: "100%" }} onClick={handleLogout}>
              Logout
            </Button>
          </Offcanvas.Body>
        </Offcanvas>
      </div>
    </div>
  );
}
