import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ThemeBox from '../../components/UI/ThemeBox';
import Typography from '@mui/material/Typography';
import { checkAuth } from "../../util/Auth";
import reqClient from "../../util/reqClient";
import { APPLIED_JOBS } from '../../constants/paths';
import { json, Link, useLoaderData, useNavigate } from "react-router-dom";
import { setToast } from '../../states/dashboard';
import { useTranslation } from "react-i18next";
import { formatDateTime } from '../../util/DateAndTime';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import ThemeBtn from '../../components/UI/ThemeBtn';

const ApplicationDetails = () => {
  const data = useLoaderData();
  const navigate = useNavigate();

  const { t } = useTranslation();

  console.log('Loader Data', data);

  return (
    <ThemeBox>
      <Row className="px-2 mt-2">
        <Col md={12}>
          <Typography 
            variant="h4"
            className="mb-3"
            sx={{ fontSize: '2rem', fontWeight: 500 }}
            >{ t('Application details') }</Typography>
        </Col>
        <Col md={12}>
          <ThemeBtn size="small" text="< Back" onClick={ () => navigate(-1) }></ThemeBtn>
        </Col>
      </Row>
      <Row className="px-2 mt-2">
        { data.application && <Col md={12} className="my-3">
          <Card variant="outlined">
            <CardContent>
              <p className="mb-2"><strong>Company:</strong> { data.application.company }</p>
              <p className="mb-2"><strong>Position:</strong> { data.application.position }</p>
              <p className="mb-2"><strong>Applied At:</strong> { data.application.created_at ? formatDateTime(data.application.created_at) : '' }</p>
              {
                data.application.link &&
                <p className="mb-2"><Link to={data.application.link} target="_blank">{data.application.link}</Link></p>
              }
              {
                data.application.description &&
                <div className="my-3" style={{whiteSpace: "pre-wrap"}}>{ data.application.description }</div>
              }
            </CardContent>
          </Card>
        </Col> }
      </Row>
    </ThemeBox>
  )
}

export default ApplicationDetails;

export const loader = async ({request, params}) => {
  if (!checkAuth()) { return null; }

  const jaId = params.jaId;

  try {
    const response = await reqClient()
      .get(`/${APPLIED_JOBS}/${jaId}`);

    const resData = response.data;

    if (resData.type === 'success') {
      return resData.data;
    }
    else {
      setToast("error", 
        resData.message ? resData.message : "Something went wrong! Please try again later.",
        true
      );
    }
  }
  catch(error) {
    let errMessage = "Unexpected error!";
    if(error.response?.data?.message) {
      errMessage = error.response.data.message;
    }

    throw json(
      {
        message: errMessage,
        role: 'admin'
      },
      { status: (error.response?.status || 500) }
    );
  }

  return null;
}

