import { Form, useParams, useNavigate, useActionData } from 'react-router-dom';
import StepTitle from './StepTitle';
//import classes from "./DetailsForm.module.scss";
import ThemeBtn from '../UI/ThemeBtn';
import ContentWrapper from './ContentWrapper';
import DynamicTextField from '../UI/DynamicTextField';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useTranslation } from "react-i18next";
import { JOB_DETAILS } from "../../constants/paths";

const ExclusionsForm = (props) => {
  const { jdId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const jobDetails = props.data?.jobDetails;
  const exCompanies = props.data?.exCompanies;
  const exJobTypes = props.data?.exJobTypes;
  const exUrls = props.data?.exUrls;

  return (
    <Form method="post">
      <ContentWrapper>
      <StepTitle className="mt-4" title={t('glossary:Exclusions')} />
        <Row className="g-0 mt-3 pt-3">
          <Col lg={6} className="pe-lg-3">
            <DynamicTextField
              sectionHead={`${t("Don't apply to these companies")}:`}
              name="exCompanies[]"
              placeholder={t("Enter company")}
              btnLabel={t("Add company")}
              limit={10}
              data={(Array.isArray(exCompanies) && exCompanies.length > 0) ? exCompanies.map(i => i.name) : ['']}
            />
            <div className="p-3"></div>
            <DynamicTextField
              sectionHead={`${t("Don't apply to these job posts (url)")}:`}
              name="exUrls[]"
              placeholder={t("Enter url")}
              btnLabel={t("Add url")}
              limit={10}
              data={(Array.isArray(exUrls) && exUrls.length > 0) ? exUrls.map(i => i.url) : ['']}
            />
          </Col>
          <Col lg={6} className="ps-lg-3">
            <DynamicTextField
              sectionHead={`${t("Don't apply to these job types")}:`}
              name="exJobTypes[]"
              placeholder={t("Enter job type")}
              btnLabel={t("Add job type")}
              limit={10}
              data={(Array.isArray(exJobTypes) && exJobTypes.length > 0) ? exJobTypes.map(i => i.type) : ['']}
            />
            <div className="p-3"></div>
            <h5 className="dashSectionSubHead mt-2 mb-3">{t("Anything else we should know?")}</h5>
            <textarea 
              name="exDesc" 
              className="form-control" 
              placeholder="Type here..." 
              rows="4"
              defaultValue={jobDetails ? jobDetails.exclusion_desc : ''}
            ></textarea>
          </Col>
        </Row>
      </ContentWrapper>
      <div className="d-flex justify-content-end">
        <ThemeBtn text="Back" type="button" className="me-3" 
          onClick={ () => { navigate(`/${JOB_DETAILS}/${jdId}/edit`); } } />
        <ThemeBtn text="Next" type="submit" variant="primary" />
      </div>
    </Form>
  )
}

export default ExclusionsForm;