import { useState } from 'react'
import ProgressBar from "../../components/progressBar/ProgressBar";
import { Outlet } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { jdSteps } from '../../states/dashboard';
import { useSnapshot } from "valtio";
import { useTranslation } from "react-i18next";
import { SUBSCRIPTION } from "../../constants/paths";
import reqClient from "../../util/reqClient";
import { checkAuth } from "../../util/Auth";

export default function Root() {
  const { t } = useTranslation();
  const jdStepsSnap = useSnapshot(jdSteps);

  let steps = [
    { title: t('Job Details') },
    { title: t('glossary:Exclusions') },
    { title: t('Upload CV & Documents') }
  ];
  if (!jdStepsSnap.isSubscribed) {
    steps.push(
      { title: t('select pricing plan') },
      { title: t('checkout') },
    );
  }

  return (
    <>
      <Row className="g-0">
        <Col>
          <ProgressBar steps={steps} active={jdStepsSnap.active}></ProgressBar>
        </Col>
      </Row>
      <Row className="g-0">
        <Col>
          <Outlet />
        </Col>
      </Row>
    </>
  )
}

export const loader = async ({params}) => {
  if (!checkAuth()) { return null; }

  try {
    const response = await reqClient()
      .get(`/${SUBSCRIPTION}/active-subscription`);

    const resData = response.data;

    if (resData?.type === 'success') {
      if(resData.data?.subscription?.id) {
        jdSteps.isSubscribed = true;
      }
      else {
        jdSteps.isSubscribed = false;
      }
    }
    // else {
    //   setToast("error", 
    //     resData.message ? resData.message : "Something went wrong! Please try again later.",
    //     true
    //   );
    //   return null;
    // }

    return null;
  }
  catch(error) {
    let errMessage = "Unexpected error!";
    if(error.response?.data?.message) {
      errMessage = error.response.data.message;
    }

    console.log(errMessage);
    return null;
  }
}
