import React from 'react';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ThemeBox from '../../components/UI/ThemeBox';
import Typography from '@mui/material/Typography';
import { checkAuth } from "../../util/Auth";
import reqClient from "../../util/reqClient";
import { ADMIN, CUSTOMERS, JOB_APPS, JOBS, APPLICATIONS } from '../../constants/paths';
import { json, useLoaderData, Form, useNavigate, useParams, redirect } from "react-router-dom";
import { setToast } from '../../states/dashboard';
import JobAppForm from '../../components/jobApps/JobAppForm';
import ThemeBtn from '../../components/UI/ThemeBtn';

const CreateJobApp = () => {
  const data = useLoaderData();
  const navigate = useNavigate();
  const { cId, jdId } = useParams();

  const cancelForm = () => {
    navigate(`/${ADMIN}/${CUSTOMERS}/${cId}/${JOBS}/${jdId}/${APPLICATIONS}`)
  }

  return (
    <ThemeBox>
      <Row className="px-2 mt-2">
        <Col>
          <Typography 
            variant="h4"
            className="mb-3"
            sx={{ fontSize: '2rem', fontWeight: 500 }}
            >Create Job Application</Typography>
        </Col>
      </Row>
      <Form method='post'>
        <Row className="px-2 mt-2">
          <Col md={12} className="my-3">
            <JobAppForm data={{ position: data.jobDetails?.title }} />
          </Col>
        </Row>
        <Row className="my-3 justify-content-center">
          <Col></Col>
          <Col sm={'auto'}>
            <ThemeBtn type="button" text="Cancel" onClick={cancelForm} />
            <ThemeBtn type="submit" text="Save" variant="primary" className="ms-3" />
          </Col>
          <Col></Col>
        </Row>
      </Form>
    </ThemeBox>
  )
}

export default CreateJobApp;

export const loader = async ({request, params}) => {
  if (!checkAuth()) { return null; }

  const jdId = params.jdId;

  try {
    const response = await reqClient('admin')
      .get(`/job-details/${jdId}`);

    const resData = response.data;

    if (resData.type === 'success') {
      return resData.data;
    }
    else {
      setToast("error", 
        resData.message ? resData.message : "Something went wrong! Please try again later.",
        true
      );
    }
  }
  catch(error) {
    let errMessage = "Unexpected error!";
    if(error.response?.data?.message) {
      errMessage = error.response.data.message;
    }

    throw json(
      {
        message: errMessage,
        role: 'admin'
      },
      { status: (error.response?.status || 500) }
    );
  }

  return null;
}

export const action = async ({request, params}) => {
  if (!checkAuth()) { return null; }

  const data = await request.formData();
  const jdId = params.jdId;
  const cId = params.cId;

  const postData = {
    company: data.get('company'),
    jobPosition: data.get('jobPosition'),
    link: data.get('link'),
    description: data.get('description')
  }

  try {
    const response = await reqClient('admin')
      .post(`/job-details/${jdId}/application/create`, postData);

    const resData = response.data;

    if (resData.type === 'success') {
      setToast("success", resData.message, true);
      return redirect(`/${ADMIN}/${CUSTOMERS}/${cId}/${JOBS}/${jdId}/${APPLICATIONS}`)
    }
    else {
      setToast("error", 
        resData.message ? resData.message : "Something went wrong! Please try again later.",
        true
      );
    }
  }
  catch(error) {
    if ((error.response?.status == 422)) {
      return error.response;
    }

    let errMessage = "Unexpected error!";
    if(error.response?.data?.message) {
      errMessage = error.response.data.message;
    }

    throw json(
      {
        message: errMessage,
        role: 'admin'
      },
      { status: (error.response?.status || 500) }
    );
  }

  return null;
}

