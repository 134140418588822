import React from 'react';
import './App.scss';
import AuthRootLayout from './pages/auth/Root';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Login, { action as submitLogin } from './pages/auth/Login';
import Register, { action as signupAction } from './pages/auth/Register';
import Landing, { loader as LandingPageLoader } from './pages/dashboard/Landing';
import { checkCustomerAuthLoader } from './util/Auth';
import JobDetails from './pages/jobDetails/JobDetails';
import JobDetailsLayout, { loader as jdLoader } from './pages/jobDetails/Root';
import CreateJobDetails, {loader as createJdLoader, action as manageJdAction} from './pages/jobDetails/CreateJobDetails';
import EditJobDetails, { loader as editJdLoader } from './pages/jobDetails/EditJobDetails';
import Exclusions, { loader as exclLoader, action as exclAction } from './pages/jobDetails/Exclusions';
import MyAccount from './pages/myAccount/MyAccount';

import DashboardLayout from './pages/dashboard/Root';
import { action as logoutAction } from './pages/auth/Logout';
import * as PATHS from './constants/paths';
import GenerateCV, { loader as CvLoader, action as CvUploader } from './pages/jobDetails/GenerateCV';
import Documents, { loader as DocsLoader, action as DocsUploader } from './pages/jobDetails/Documents';
import PricingPlan, { loader as PlansLoader } from './pages/jobDetails/PricingPlan';
import Checkout, { action as CheckoutAction, loader as CheckoutLoader } from './pages/jobDetails/Checkout';
import SubscriptionStatus from './pages/dashboard/SubscriptionStatus';
import Profile, { loader as ProfileLoader, action as ProfileAction } from './pages/dashboard/Profile';
import AppliedJobs, { loader as AppliedJobsLoader } from './pages/dashboard/AppliedJobs';
import ApplicationDetails, { loader as AppDetailsLoader } from './pages/dashboard/ApplicationDetails';
import ForgotPassword, { action as ForgotPwdAction } from './pages/auth/ForgotPassword';
import ResetPassword, { loader as ResetPwdLoader, action as ResetPwdAction } from './pages/auth/ResetPassword';

import adminRouter from './routes/admin';
import ErrorPage from './pages/ErrorPage';
import HomeLayout from './pages/home/Root';
import HomeLanding, { loader as LandingLoader } from './pages/home/Landing';
import TermsOfService from './pages/home/TermsOfService';
import PrivacyPolicy from './pages/home/PrivacyPolicy';

const router = createBrowserRouter([
  {
    errorElement: <ErrorPage />,
    children: [
      // {
      //   path: '/home',
      //   element: <Home />
      // },

      {
        path: PATHS.HOME,
        element: <HomeLayout />,
        children: [
          {
            index: true,
            element: <HomeLanding />,
            loader: LandingLoader
          },
          {
            path: 'terms-of-service',
            element: <TermsOfService />
          },
          {
            path: 'privacy-policy',
            element: <PrivacyPolicy />
          }
        ]
      },

      {
        element: <AuthRootLayout />,
        children: [
          { 
            path: PATHS.LOGIN,
            element: <Login />,
            action: submitLogin
          },
          { 
            path: PATHS.REGISTER,
            element: <Register />,
            action: signupAction
          },
          { 
            path: `${PATHS.FORGOT_PWD}`,
            element: <ForgotPassword />,
            action: ForgotPwdAction
          },
          { 
            path: `${PATHS.PWD_RESET}`,
            element: <ResetPassword />,
            loader: ResetPwdLoader,
            action: ResetPwdAction
          }
        ]
      },

      {
        //path: PATHS.DASHBOARD,
        element: <DashboardLayout />,
        loader: checkCustomerAuthLoader,
        children: [
          {
            //index: true,
            path: PATHS.DASHBOARD,
            element: <Landing />,
            loader: LandingPageLoader
          },
          {
            path: PATHS.LOGOUT,
            action: logoutAction
          },
          {
            path: PATHS.SUBSCRIPTION + '/:status',
            element: <SubscriptionStatus />
          },
          {
            path: PATHS.PROFILE,
            element: <Profile />,
            loader: ProfileLoader,
            action: ProfileAction
          },
          {
            path: PATHS.APPLIED_JOBS,
            element: <AppliedJobs />,
            loader: AppliedJobsLoader
          },
          {
            path: PATHS.APPLIED_JOBS + '/:jaId',
            element: <ApplicationDetails />,
            loader: AppDetailsLoader
          },
          {
            path: PATHS.JOB_DETAILS,
            element: <JobDetailsLayout />,
            loader: jdLoader,
            children: [
              {
                path: 'create',
                element: <CreateJobDetails />,
                loader: createJdLoader,
                action: manageJdAction
              },
              {
                path: ':jdId/edit',
                element: <EditJobDetails />,
                loader: editJdLoader,
                action: manageJdAction
              },
              {
                path: ':jdId/exclusions',
                element: <Exclusions />,
                loader: exclLoader,
                action: exclAction
              },
              {
                path: ':jdId/generate-cv',
                element: <GenerateCV />,
                loader: CvLoader,
                action: CvUploader
              },
              {
                path: ':jdId/documents',
                element: <Documents />,
                loader: DocsLoader,
                action: DocsUploader
              },
              {
                path: ':jdId/pricing',
                element: <PricingPlan />,
                loader: PlansLoader
              },
              {
                path: ':jdId/pricing/:planId/checkout',
                element: <Checkout />,
                loader: CheckoutLoader,
                action: CheckoutAction
              }
            ]
          }
        ]
      },

      ...adminRouter,
      
      {
        path: "/jobdetails-template",
        element: <JobDetails/>
      },
      {
        path: "/profile-template",
        element: <MyAccount/>
      }
    ]
  }
]);

function App() {
  return (
    <>
      <RouterProvider router={router} />
    </>
  );
}

export default App;
