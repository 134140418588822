import React from 'react';
import reqClient from "../../util/reqClient";
import { JOB_DETAILS } from "../../constants/paths";
import { setToast } from '../../states/dashboard';
import { redirect, useLoaderData } from 'react-router-dom';
import { jdSteps } from '../../states/dashboard';
import { checkAuth } from "../../util/Auth";
import ExclusionsForm from '../../components/jobDetails/ExclusionsForm';

const Exclusions = () => {
  jdSteps.active = 1;
  const data = useLoaderData();

  return (
    <ExclusionsForm data={data} />
  )
}

export default Exclusions;

export const loader = async ({params}) => {
  if (!checkAuth()) { return null; }

  try {
    const response = await reqClient()
      .get(`/${JOB_DETAILS}/${params.jdId}/exclusions`);

    const resData = response.data;

    if (resData?.data) {
      return resData.data;
    }
    else {
      setToast("error", 
        resData.message ? resData.message : "Something went wrong! Please try again later.",
        true
      );
      return null;
    }
  }
  catch(error) {
    let errMessage = "Unexpected error!";
    if(error.response?.data?.message) {
      errMessage = error.response.data.message;
    }

    setToast("error", errMessage, true);
    return null;
  }
}

export const action = async ({request, params}) => {
  const formData = await request.formData();
  let jdId = params.jdId;

  let postData = {
    exCompanies: formData.getAll('exCompanies[]'),
    exUrls: formData.getAll('exUrls[]'),
    exJobTypes: formData.getAll('exJobTypes[]'),
    exDesc: formData.get('exDesc')
  }

  //console.log('postData', postData);return null;

  try {
    const response = await reqClient()
      .post(`/${JOB_DETAILS}/${jdId}/exclusions`, postData);
    
    if(response.data.type === 'failed') {
      setToast(
        "error", 
        response.data.message ? response.data.message : "Error saving data. Please try again later.",
        true
      );
      return null;
    }

    return redirect(`/${JOB_DETAILS}/${jdId}/generate-cv`);
  }
  catch (error) {
    if ((error.response?.status == 422)) {
      return error.response;
    }

    let errMessage = "Unexpected error!";
    if(error.response.data?.message) {
      errMessage = error.response.data?.message;
    }

    setToast("error", errMessage, true);
  }

  return null;
}