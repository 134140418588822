import React from 'react';
import Box from '@mui/material/Box';

const TableResponsiveWrapper = (props) => {
  return (
    <Box sx={{ overflow: "auto" }}>
      <Box sx={{ width: "100%", display: "table", tableLayout: "fixed" }}>
        {props.children}
      </Box>
    </Box>
  )
}

export default TableResponsiveWrapper;
