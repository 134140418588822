import { Form, useActionData } from 'react-router-dom';
import Input from '@mui/material/Input';
import FormHelperText from '@mui/material/FormHelperText';
import classes from "./DetailsForm.module.scss";
import { useState } from "react";
import ThemeBtn from '../UI/ThemeBtn';
import { BsArrowRight } from "react-icons/bs";
import ContentWrapper from './ContentWrapper';
import DynamicTextField from '../UI/DynamicTextField';
import Divider from '@mui/material/Divider';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import DynamicAutoComplete from '../UI/DynamicAutoComplete';
import { useTranslation } from "react-i18next";

export default function DetailsForm(props) {
  const { t } = useTranslation();
  const jobDetails = props.data?.jobDetails;
  const countries = props.data?.countries;
  const languages = props.data?.languages;
  const intrTitles = props.data?.intrTitles ? props.data?.intrTitles.map((i) => { return i.title; }) : [];
  const intrCountries = props.data?.intrCountries || null;
  const intrLanguages = props.data?.intrLanguages || null;

  const [countryId, setCountryId] = useState((jobDetails?.country_id || ""));
  const [languageId, setLanguageId] = useState((jobDetails?.language_id || ""));

  const resData = useActionData();
  const formErrors = resData?.data?.errors;

  return (
    <Form method={props.method} action={props.action}>
      <ContentWrapper>
        <Input 
          className={classes.jobTitle} 
          placeholder={t("Enter job title")}
          name="title"
          error={formErrors?.title ? true : false}
          defaultValue={jobDetails ? jobDetails.title : ''}
        />
        {formErrors?.title && 
          <FormHelperText className={classes.titleHelperText} error={true}>{formErrors?.title}</FormHelperText>
        }
        <p className="dashHelperText mt-2 mb-3 pb-3">{`${t("We will prioritize applications that match this title.")} ${t("If you're interested in more options, you can add them below.")}`}</p>
        <DynamicTextField
          sectionHead={t("I'm also interested in:")}
          name="intrTitles[]"
          placeholder={t("Enter job title")}
          btnLabel={t("Add title")}
          data={intrTitles}
        />
        <Divider sx={{opacity: '0.9'}} className="pt-3" />
        <Row className="g-0 mt-3 pt-3">
          <Col lg={6} className="pe-lg-2">
            <h4 className="dashSectionHead mb-3">{t("Preferred location")}</h4>
            <FormControl sx={{ width: '100%' }}>
              <Select
                value={countryId}
                onChange={(e) => { setCountryId(e.target.value) }}
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
                name="country"
                error={formErrors?.country ? true : false}
              >
                <MenuItem value="">
                  <em>Select country</em>
                </MenuItem>
                {countries.map(country => (
                  <MenuItem key={country.name} value={country.id}>{country.name}</MenuItem>
                ))}
              </Select>
              {formErrors?.country && 
              <FormHelperText error={true}>{formErrors?.country}</FormHelperText>
              }
            </FormControl>
            <p className="dashHelperText mt-2 mb-3 pb-3">{t("We will prioritize applications that match this location.")}</p>
            <DynamicAutoComplete 
              sectionHead={t("I'm also interested in:")}
              options={countries}
              btnLabel={t("Add countries")}
              name="intrCountries[]"
              defaultValue={intrCountries}
            />
          </Col>
          <Col lg={6} className="ps-lg-2">
            <h4 className="dashSectionHead mb-3">{t("Preferred language")}</h4>
            <FormControl sx={{ width: '100%' }}>
              <Select
                value={languageId}
                onChange={(e) => { setLanguageId(e.target.value) }}
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
                name="language"
                error={formErrors?.language ? true : false}
              >
                <MenuItem value="">
                  <em>Select language</em>
                </MenuItem>
                {languages.map(language => (
                  <MenuItem key={language.name} value={language.id}>{language.name}</MenuItem>
                ))}
              </Select>
              {formErrors?.language && 
              <FormHelperText error={true}>{formErrors?.language}</FormHelperText>
              }
            </FormControl>
            <p className="dashHelperText mt-2 mb-3 pb-3">{t("We will prioritize applications that match this language.")}</p>
            <DynamicAutoComplete 
              sectionHead={t("I'm also interested in:")}
              options={languages}
              btnLabel={t("Add languages")}
              name="intrLanguages[]"
              defaultValue={intrLanguages}
            />
          </Col>
        </Row>

      </ContentWrapper>
      <div className="d-flex justify-content-end">
        <ThemeBtn text="Next" type="submit" variant="primary"><BsArrowRight /></ThemeBtn>
      </div>
    </Form>
  );
}
