import { redirect } from 'react-router-dom';
import { currentUser } from "../../util/Auth"
import { LOGIN, ADMIN } from "../../constants/paths"

export function action() {
  const user = currentUser();

  let redirectPath = `/${LOGIN}`;
  if (user?.role === 'admin') {
    redirectPath = `/${ADMIN}/${LOGIN}`;
  }

  localStorage.removeItem('accToken');
  localStorage.removeItem('currentUser');
  localStorage.removeItem('accExpire');
  return redirect(redirectPath);
}