import React from 'react';
import { BsTrash } from "react-icons/bs";
import { FaRegFileAlt, FaRegFilePdf, FaRegFileImage } from "react-icons/fa";
import classes from "./UploadedItem.module.scss";

export default function UploadedItem(props) {
  const viewItem = (e) => {
    e.preventDefault();
    if (props.viewItemHandler) {
      props.viewItemHandler(props.itemId);
    }
    else {
      return false;
    }
  }

  const deleteItem = (e) => {
    e.preventDefault();
    if (props.delItemHandler) {
      props.delItemHandler(props.itemId);
    }
    else {
      return false;
    }
  }

  let fileIcon = '';
  const ext = props.name.split('.').pop();
  if (ext.toLowerCase() === 'pdf') {
    fileIcon = <FaRegFilePdf className={classes.fileImg} />;
  }
  else if(['jpg', 'jpeg', 'png'].includes(ext.toLowerCase())) {
    fileIcon = <FaRegFileImage className={classes.fileImg} />;
  }
  else {
    fileIcon = <FaRegFileAlt className={classes.fileImg} />;
  }

  return (
    <div className={`${classes.file} ${props.className ? props.className : ''}`}>
      { fileIcon }
      <p className={classes.text}>
        { props.viewItemHandler ?
          <a onClick={viewItem}>{props.name}</a> :
          props.name
        }
      </p>
      <p className={`${classes.text} ${classes.textFileSize}`} style={{display: 'none'}}></p>
      <div className={classes.iconBigScreen}>
        {
          props.delItemHandler &&
          <a onClick={deleteItem} className="ms-2"><BsTrash className={classes.icon} /></a>
        }
      </div>
    </div>
  )
}
