import React, { useState } from "react";
import classes from "./progressBar.module.scss";
import { Step, StepLabel, Stepper, useMediaQuery } from "@mui/material";
import CircularStepper from "./CircularStepper/CircularStepper";

export default function ProgressBar(props) {
  const activeStep = props.active;
  const steps = props.steps ? props.steps: [];
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  return (
    <div className={classes.container}>
      {!isSmallScreen && (
        <Stepper
          className={classes.stepper}
          activeStep={activeStep}
          alternativeLabel
          sx={{
            "& .MuiStepIcon-root": { width: "40px", height: "40px" },
            "& .MuiStepConnector-root": { top: "20px" },
            "& .MuiStepLabel-label": {
              fontSize: "16px",
              lineHeight: "24px",
              marginTop: "8px",
            },
          }}
        >
          {steps.map((label) => (
            <Step key={label.title}>
              <StepLabel>{label.title}</StepLabel>
            </Step>
          ))}
        </Stepper>
      )}
      {isSmallScreen && (
        <CircularStepper
          activeStep={activeStep}
          steps={steps}
        ></CircularStepper>
      )}
    </div>
  );
}
