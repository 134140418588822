import { useState } from 'react'
import classes from './ThemeTable.module.scss'
import Table from 'react-bootstrap/Table';
import { BsSortUp, BsSortDown } from "react-icons/bs";
import { Link } from "react-router-dom";
import Button from 'react-bootstrap/Button';

const ThemeTable = (props) => {
  const tableSort = (accessor, order) => {
    if (props.sortHandler) {
      props.sortHandler(accessor, order);
    }
  }

  return (
    <>
      <Table className={classes.themeTable}>
        <thead className={classes.thead}>
          <tr>
            {
              props.head.map((item, i) => {
                return (<ThemeTableTh key={i} data={item} tableSort={tableSort} />)
              })
            }
          </tr>
        </thead>
        <tbody>
          {(props.tableData.length > 0) && props.tableData.map((row, i) => {
            return (<ThemeTableRow key={i} data={row} />)
          })}
        </tbody>
      </Table>
      {
        (props.tableData.length < 1) &&
        <div className="d-flex justify-content-center"><p>No records found.</p></div>
      }
    </>
  )
}

export default ThemeTable;

export const ThemeTableTh = (props) => {
  const sortOrder = props.data.sort;
  //console.log([props.data.accessor, props.data.sort]);

  const thSort = (prevOrder) => {
    let order = '';
    if (prevOrder === 'asc') {
      order = 'desc';
    }
    else if (prevOrder === 'desc') {
      order = '';
    }
    else {
      order = 'asc';
    }
    props.tableSort(props.data.accessor, order);
  }

  const sortIcon = (order) => {
    return (
      <>
        {order === 'asc' && <BsSortUp />}
        {order === 'desc' && <BsSortDown />}
      </>
    )
  }

  return (
    <>
      {props.data.sortable && <th className={classes.th}
        onClick={() => { thSort(sortOrder) }}><span className={classes.sortable}>{props.data.label} {sortIcon(sortOrder)}</span>
      </th>}
      {!props.data.sortable && <th className={classes.th}>{props.data.label}</th>}
    </>
  );
}

export const ThemeTableRow = (props) => {
  const standOutIndices = props.data.standOutIndices || [];

  return (
    <tr className={classes.tr}>
      {
        props.data.cells.map((col, i) => {
          return <td key={i} 
            className={`${ standOutIndices.includes(i) ? classes.standOutText : '' }`}
          >
            {typeof(col) === 'string' ? col: ''}
            {(typeof(col) === 'object' && col.type === 'btn') &&
              <Link to={col.url} className={`btn btn-outline-primary ${classes.tblBtn}`}>{col.label}</Link>
            }
            {(typeof(col) === 'object' && col.type === 'link') &&
              <Link to={col.url}>{col.label}</Link>
            }
            { (typeof(col) === 'object' && col.type === 'delete') &&
              <Button type="button" variant="link" onClick={col.onClick} className={classes.tblDelete}>{col.label}</Button>
            }
          </td>
        })
      }
    </tr>
  );
}
