import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import classes from './ConfirmModal.module.scss';
import Button from './ThemeBtn';
import Modal from 'react-bootstrap/Modal';

export default function ConfirmModal(props) {
  const show = props.show;
  const handleClose = () => {
    props.handleAction(false);
  }

  const confirmAction = () => {
    props.handleAction(true, props?.data);
  }

  return (
    <>
      { createPortal(
        <Modal show={show} onHide={handleClose} animation={true} className={classes.mdlWrapper}>
          <Modal.Header className={`${classes.header}`}>
            <Modal.Title>{ props.title || 'Confirm Action' }</Modal.Title>
          </Modal.Header>
          <Modal.Body className={classes.mdlBody}>{ props.message || 'Are you sure you want to do this?' }</Modal.Body>
          <Modal.Footer className={classes.footer}>
            <Button 
              variant="outline-primary" 
              text="Close"
              onClick={handleClose}
              className={classes.mdlBtn}
            ></Button>
            <Button 
              variant="primary"
              text="Proceed"
              onClick={confirmAction}
              className={`${classes.mdlBtn} ${classes.colorWhite}`}
            ></Button>
          </Modal.Footer>
        </Modal>,
        document.getElementById('overlays')
      ) }
    </>
  )
}
