import { useState } from 'react'
import { jdSteps } from '../../states/dashboard';
import StepTitle from "../../components/jobDetails/StepTitle";
import ContentWrapper from '../../components/jobDetails/ContentWrapper';
import SelectPlan from '../../components/jobDetails/SelectPlan';
import { JOB_DETAILS } from "../../constants/paths";
import { checkAuth } from "../../util/Auth";
import { setToast } from '../../states/dashboard';
import reqClient from "../../util/reqClient";
import { useLoaderData, useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ThemeBtn from "../../components/UI/ThemeBtn";
import { BsArrowRight } from "react-icons/bs";

export default function PricingPlan() {
  jdSteps.active = 3;

  const { t } = useTranslation();
  const data = useLoaderData();
  const { jdId } = useParams();
  const navigate = useNavigate();
  //console.log('Loader Data', data);

  const defaultPlanId = data?.activePlanId || data?.defaultPlanId;
  const [selectedPlanId, setSelectedPlanId] = useState(defaultPlanId);

  const onPlanSelect = (planId) => {
    //console.log('On plan select', planId);
    setSelectedPlanId(planId);
  }

  const moveToNextStep = () => {
    navigate(`/${JOB_DETAILS}/${jdId}/pricing/${selectedPlanId}/checkout`);
  }

  return (
    <>
      { data &&
      <>
        <ContentWrapper>
          <StepTitle className="mt-4" title={t('select pricing plan')} />
          <SelectPlan plans={data.plans} defaultId={selectedPlanId} onSelect={onPlanSelect} />
        </ContentWrapper>
        <div className="d-flex justify-content-end">
          <ThemeBtn text={t('back')} type="button" className="me-3" 
            onClick={ () => { navigate(`/${JOB_DETAILS}/${jdId}/generate-cv`); } } />
          <ThemeBtn text={t('next')} type="button" variant="primary" onClick={moveToNextStep}></ThemeBtn>
        </div>
      </>
      }
    </>
  )
}

export const loader = async ({params}) => {
  if (!checkAuth()) { return null; }

  try {
    const response = await reqClient()
      .get(`/${JOB_DETAILS}/${params.jdId}/pricing-plans`);

    const resData = response.data;

    if (resData.type === 'success') {
      return resData.data;
    }
    else {
      setToast("error", 
        resData.message ? resData.message : "Something went wrong! Please try again later.",
        true
      );
      return null;
    }
  }
  catch(error) {
    let errMessage = "Unexpected error!";
    if(error.response?.data?.message) {
      errMessage = error.response.data.message;
    }

    setToast("error", errMessage, true);
    return null;
  }
}
