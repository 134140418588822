import React, { useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { Form, InputGroup } from "react-bootstrap";
import RightHeader from "../../components/auth/RightHeader";
import MainHeading from "../../components/auth/MainHeading";
import classes from "./Register.module.scss";
import { Form as ReactRouterForm, useActionData, useLoaderData, json, redirect } from "react-router-dom";
import { setToast } from '../../states/dashboard';
import { useTranslation } from "react-i18next";
import { getQueryParams } from "../../util/Helper";
import reqClient from "../../util/reqClient";
import { BsEye, BsEyeSlash } from "react-icons/bs";

export default function ResetPassword() {
  const { t } = useTranslation();

  const [passwordVisible, setPasswordVisible] = useState(true);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const loaderData = useLoaderData();
  const user = loaderData?.user;
  console.log('Loader Data', loaderData);

  const actionResponse = useActionData();
  const data = actionResponse?.data;

  return (
    <>
      <RightHeader
        title={t("Already have an account")}
        btn={t("login")}
        url={"/login"}
      />
      <MainHeading title={t("Reset password")} />
      <Row md={12}>
        <Col className={classes.formWrapper}>
          <ReactRouterForm method='post' noValidate>

            <Form.Group className="mb-4" controlId="email">
              <Form.Label>{t("email address")}</Form.Label>
              <Form.Control 
                type="email"
                name="email"
                isInvalid={data && data.errors?.email}
                value={user?.email}
                readOnly={true}
              />
              {data && data.errors?.email &&
                <Form.Control.Feedback type="invalid">
                  {data.errors?.email}
                </Form.Control.Feedback>
              }
            </Form.Group>
            
            <Form.Group className="mb-4" controlId="password">
              <Form.Label>Password</Form.Label>
              <InputGroup hasValidation={(data && data.errors?.password) ? true: false}>
                <Form.Control
                  type={passwordVisible ? "text" : "password"}
                  placeholder="Password"
                  name="password"
                  style={{ backgroundColor: "white", borderRight: "none" }}
                  isInvalid={data && data.errors?.password}
                />
                <InputGroup.Text
                  style={{ backgroundColor: "white" }}
                  className="pr-5"
                  onClick={togglePasswordVisibility}
                >
                  {passwordVisible ? <BsEye /> : <BsEyeSlash />}
                </InputGroup.Text>
                {data && data.errors?.password &&
                  <Form.Control.Feedback type="invalid">
                    {data.errors?.password}
                  </Form.Control.Feedback>
                }
              </InputGroup>
            </Form.Group>

            <div className="d-grid gap-2 mt-5">
              <Button variant="primary" className={`themeBtnFilled`} type="submit">
                { t("Submit") }
              </Button>
            </div>
          </ReactRouterForm>
        </Col>
      </Row>
    </>
  );
}

const loader = async ({request, params}) => {
  const queryParams = getQueryParams(request.url);
  let role;

  if (queryParams?.type === 'admin') {
    role = 'admin';
  }
  else {
    role = 'customer'
  }

  console.log('Inside Loader', [queryParams, role]);

  try {

    const response = await reqClient(role).get(
      `/get-password-reset?token=${queryParams.token}`
    );

    const resData = response.data;

    if (resData.type === 'success') {
      return resData.data;
    }
    else {
      setToast("error", 
        resData.message ? resData.message : "Something went wrong! Please try again later.",
        true
      );
    }
  }
  catch (error) {
    let errMessage = "Unexpected error!";
    if(error.response?.data?.message) {
      errMessage = error.response.data.message;
    }

    throw json(
      {
        message: errMessage,
        role: role
      },
      { status: (error.response?.status || 500) }
    );
  }


  return null;
}

const action = async ({request, params}) => {
  const data = await request.formData();

  const queryParams = getQueryParams(request.url);
  let role;

  if (queryParams?.type === 'admin') {
    role = 'admin';
  }
  else {
    role = 'customer'
  }

  const postData = {
    password: data.get('password')
  }

  console.log('Inside action');
  console.log([postData, queryParams]);

  try {

    const response = await reqClient(role).post(
      `/reset-password?token=${queryParams.token}`,
      postData
    );

    const resData = response.data;
    if (resData?.type === 'success') {
      setToast("success", resData.message, true);
      return redirect(role === 'admin' ? '/admin/login' : '/login');
    }
    else {
      setToast("error", "Something went wrong. Please try again later.", true);
    }
  }
  catch (error) {
    if ((error.response?.status == 422)) {
      return error.response;
    }
    
    let errMessage = "Unexpected error!";
    if(error.response.data?.message) {
      errMessage = error.response.data?.message;
    }

    setToast("error", errMessage, true);
  }

  return null;
}

export { loader, action };
