import React from 'react';
import classes from './ThemeOutlinedCard.module.scss'

const ThemeOutlinedCard = (props) => {
  return (
    <div className={`${classes.thmCard} ${props.className ? props.className : ''}`}>{props.children}</div>
  )
}

export default ThemeOutlinedCard;
