import StepTitle from "../../components/jobDetails/StepTitle";
import FileUploadContainer from "../../components/UI/FileUploadContainer";
import ContentWrapper from '../../components/jobDetails/ContentWrapper';
import ThemeBtn from "../../components/UI/ThemeBtn";
import { BsArrowRight } from "react-icons/bs";
import { checkAuth } from "../../util/Auth";
import { setToast } from '../../states/dashboard';
import reqClient from "../../util/reqClient";
import { JOB_DETAILS, DASHBOARD } from "../../constants/paths";
import { useLoaderData, useParams, useSubmit, useNavigate } from "react-router-dom";
import { getQueryParams } from "../../util/Helper";
import BoxSubHeading from "../../components/UI/BoxSubHeading";
import UploadedItem from "../../components/UI/UploadedItem";
import ConfirmModal from "../../components/UI/ConfirmModal";
import { useState } from "react";
import { jdSteps } from '../../states/dashboard';
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";

export default function GenerateCV() {
  const jdStepsSnap = useSnapshot(jdSteps);
  
  jdSteps.active = 2;

  const loaderData = useLoaderData();
  const resumes = loaderData?.resumes;
  const documents = loaderData?.documents;

  const [showConfirm, setShowConfirm] = useState(false);
  const [delCvId, setDelCvId] = useState();

  const { jdId } = useParams();
  const { t } = useTranslation();

  const submit = useSubmit();
  const navigate = useNavigate();

  const uploadHandler = async (files, type = 'resume') => {

    if (files.length < 1) {
      return false;
    }

    let formData = new FormData();
    files.forEach(file => {
      formData.append('upload', file);
    });

    formData.append('docType', type);

    submit(formData, {
      action: `/${JOB_DETAILS}/${jdId}/generate-cv`,
      method: "post",
      encType: 'multipart/form-data'
    });
  }

  const viewDocument = async (id, type = 'resume') => {
    let url = type === 'resume' ? `/${JOB_DETAILS}/${jdId}/resumes/${id}` :
      `/${JOB_DETAILS}/${jdId}/documents/${id}`;

    try {
      const response = await reqClient()
        .get(url);
  
      const resData = response.data;
  
      if (resData.data?.url) {
        //window.open(resData.data.url, '_blank', 'noreferrer');
        window.location.replace(resData.data.url)
      }
      else {
        setToast("error", 
          resData.message ? resData.message : "Something went wrong! Please try again later.",
          true
        );
      }
    }
    catch(error) {
      let errMessage = "Unexpected error!";
      if(error.response?.data?.message) {
        errMessage = error.response.data.message;
      }
  
      setToast("error", errMessage, true);
    }
  }

  const handleConfirmAction = (value = false, data = null) => {
    setShowConfirm(false);

    if (value === true) {
      let formData = new FormData();
      formData.append('id', data.id);
      formData.append('type', data.type);

      submit(formData, {
        action: `/${JOB_DETAILS}/${jdId}/generate-cv`,
        method: "delete"
      });
    }
    else {
      return;
    }
  }

  const initiateDeleteCv = (id, type) => {
    setDelCvId({id, type});
    setShowConfirm(true);
  }

  const moveToNextStep = () => {
    if (resumes && resumes.length > 0) {
      if(jdStepsSnap.isSubscribed) {
        navigate(`/${DASHBOARD}`);
      }
      else {
        navigate(`/${JOB_DETAILS}/${jdId}/pricing`);
      }
    }
    else {
      setToast("error", t("Please upload a CV to continue."), true);
    }
  }

  return (
    <>
      <ContentWrapper>
        <StepTitle className="mt-4" title={t('Upload Resume')} />
        <FileUploadContainer
          dropHandler={ (files) => { uploadHandler(files) } }
          className="mt-3"
          type="generate_cv"
          dzOptions={{
            noClick: true,
            noKeyboard: true,
            multiple: false
          }}
          extensions={['pdf']}
        />

        { resumes && resumes.length > 0 &&
          <BoxSubHeading text="Uploaded CV" />
        }

        { resumes && resumes.length > 0 &&
          resumes.map(resume => {
            return <UploadedItem 
              key={resume.id} 
              itemId={resume.id} 
              name={resume.name} 
              className="mb-4"
              viewItemHandler={ (id) => { viewDocument(id) } }
              delItemHandler={ (id) => { initiateDeleteCv(id, 'resume') } }
            />
          })
        }

        <StepTitle className="mt-5 pt-4" title={`${t('Upload Documents')} (${t('glossary:optional')})`} />
        <p className="my-4">{ t('Upload docs description') }</p>
        <FileUploadContainer
          dropHandler={ (files) => { uploadHandler(files, 'document') } }
          className="mt-3"
          type="upload_docs"
          dzOptions={{
            noClick: false,
            noKeyboard: true,
            multiple: true
          }}
          extensions={['pdf', 'jpg', 'jpeg', 'png']}
        />

        { documents && documents.length > 0 &&
          <BoxSubHeading text="Documents" />
        }

        { documents && documents.length > 0 &&
           documents.map(document => {
            return <UploadedItem 
              key={document.id} 
              itemId={document.id} 
              name={document.name} 
              className="mb-4"
              viewItemHandler={ (id) => { viewDocument(id, 'document') } }
              delItemHandler={ (id) => { initiateDeleteCv(id, 'document') } }
            />
          })
        }
      </ContentWrapper>
      <div className="d-flex justify-content-end">
        <ThemeBtn text="Back" type="button" className="me-3" 
          onClick={ () => { navigate(`/${JOB_DETAILS}/${jdId}/exclusions`); } } />
        <ThemeBtn text={ jdStepsSnap.isSubscribed ? t("save") : t("next") } type="button" variant="primary" onClick={moveToNextStep} />
      </div>
      <ConfirmModal 
        key="cnfrm_del_resume" 
        message={t("Are you sure you want to delete this CV?")}
        show={showConfirm}
        handleAction={handleConfirmAction}
        data={delCvId}
      />
    </>
  );
}

export const loader = async ({params}) => {
  if (!checkAuth()) { return null; }

  try {
    const response = await reqClient()
      .get(`/${JOB_DETAILS}/${params.jdId}/documents`);

    const resData = response.data;

    if (resData.type === 'success') {
      return resData.data;
    }
    else {
      setToast("error", 
        resData.message ? resData.message : "Something went wrong! Please try again later.",
        true
      );
      return null;
    }
  }
  catch(error) {
    let errMessage = "Unexpected error!";
    if(error.response?.data?.message) {
      errMessage = error.response.data.message;
    }

    setToast("error", errMessage, true);
    return null;
  }
}

export const action = async ({request, params}) => {

  const queryParams = getQueryParams(request.url);
  const formData = await request.formData();

  try {
    if (request.method === 'POST') {
      let postUrl = formData.get('docType') === 'resume' ? `/${JOB_DETAILS}/${params.jdId}/upload-resume` : 
        (formData.get('docType') === 'document' ? `/${JOB_DETAILS}/${params.jdId}/documents/upload` : '');

      const response = await reqClient().post(
        postUrl,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        }
      );

      if(response.data.status === 'failed') {
        setToast(
          "error", 
          response.data.message ? response.data.message : "Error saving data. Please try again later.",
          true
        );
        return null;
      }

      setToast("success", "Successfully uploaded!", true);
      return null;
    }
    else if (request.method === 'DELETE') {
      let delId = formData.get('id');
      let delType = formData.get('type');
      let delUrl = delType === 'resume' ? `/${JOB_DETAILS}/${params.jdId}/resumes/${delId}` : 
        (delType === 'document' ? `/${JOB_DETAILS}/${params.jdId}/documents/${delId}` : '')

      const response = await reqClient().delete(delUrl);

      if(response.data.status === 'failed') {
        setToast(
          "error", 
          response.data.message ? response.data.message : "Error deleting file. Please try again later.",
          true
        );
        return null;
      }

      setToast("success", "Successfully deleted!", true);
      return null;
    }
  }
  catch(error) {
    let errMessage = "Unexpected error!";
    if(error.response?.data?.message) {
      errMessage = error.response.data.message;
    }

    setToast("error", errMessage, true);
    return null;
  }

  return null;
}
