import classes from "./document.module.scss";
import pdfFile from "../../../assets/images/pdf_file.jpg";
import wordFile from "../../../assets/images/word_file.jpg";
import editIcon from "../../../assets/icons/Pen.svg";
import infoImg from "../../../assets/icons/info.png";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export default function Document({ title, file, text }) {
  return (
    <div className={classes.wrapper}>
      <p className={classes.title}>{title}</p>

      <div className={classes.fileContainer}>
        {file && (
          <div className={classes.file}>
            <img
              className={classes.fileImg}
              src={file?.type === "pdf" ? pdfFile : wordFile}
              alt="file"
            />
            <p className={classes.fileName}>{file?.name}</p>
          </div>
        )}
        {text && (
          <p className={classes.text}>
            {text}
            <OverlayTrigger
              key="top"
              placement="top"
              overlay={
                <Tooltip id={`tooltip-dfdfdf`}>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Sint
                  vitae ab cumque facere voluptate perspiciatis ex cupiditate
                  totam dolore at aliquam nulla ipsam minima doloribus
                  architecto optio alias qui, nemo consequatur nostrum illum
                  reiciendis. Nobis esse neque saepe modi quam?
                </Tooltip>
              }
            >
              <img className={classes.infoImg} src={infoImg} alt="infoImg" />
            </OverlayTrigger>
          </p>
        )}
        <a href="#">
          <img className={classes.editIcon} src={editIcon} alt="editIcon" />
        </a>
      </div>
    </div>
  );
}
