import React, {useCallback} from 'react'
import classes from './FileUploadContainer.module.scss'
import {useDropzone} from 'react-dropzone';
import Button from "react-bootstrap/Button";
import Paper from '@mui/material/Paper';
import { useTranslation } from "react-i18next";

const extValidator = (file, allowedExts) => {
  if (file && file instanceof File) {
    const ext = file.name.split('.').pop();
    if (ext && allowedExts.includes(ext.toLowerCase())) {
      return true;
    }
  }

  return false;
}

export default function FileUploadContainer(props) {
  const { t } = useTranslation();

  const onDrop = useCallback(acceptedFiles => {
    // Do something with the files
    props.dropHandler(acceptedFiles);
  }, [])

  const sizeLimit = 5; // in MB
  const allowedExts = (props.extensions ? props.extensions : ['pdf']);
  const validator = (file) => {
    if (extValidator(file, allowedExts) === false) {
      return {
        code: "incorrect-extension",
        message: `Only files with the following extensions are allowed: ${allowedExts.join(" ")}`
      };
    }

    if (file.size > (sizeLimit * 1048576)) {
      return {
        code: "size-too-large",
        message: `File size cannot be greater than ${sizeLimit}MB`
      };
    }   
    
    return null;
  }

  const {getRootProps, getInputProps, fileRejections, isDragActive, open} = useDropzone({
    onDrop: onDrop,
    validator: validator,
    ...(props.dzOptions ? props.dzOptions : {})
  })

  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <li key={file.path} className="mb-3">
      <p>
        {`${file.name}: `} <span>{errors.map(e => (e.message))}</span>
      </p>
    </li>
  ));

  let innerContent = '';
  if(props.type === 'generate_cv') {
    /*innerContent  = (
      <>
        <Button className={`flUploadCtrBtn`} variant="outline-primary">
          Generate CV from Linkedin
        </Button>
        <p className={`flCtrBtnSepTxt`}>OR</p>
        <Button className={`flUploadCtrBtn`} variant="primary" onClick={open}>
          Upload Resume
        </Button>
      </>
    );*/
    innerContent  = (
      <>
        <Button className={`flUploadCtrBtn`} variant="outline-primary" onClick={open}>
          {t('Upload Resume')}
        </Button>
        <p className={`flCtrBtnSepTxt`}>OR</p>
        <p className={classes.instructionNotes}>{t('Drag and drop file here')}</p>
      </>
    );
  }
  else if(props.type === 'upload_docs') {
    innerContent = (
      <>
        <Button className={`flUploadCtrBtn`} variant="outline-primary">
          {t('Upload Documents')}
        </Button>
        <p className={`flCtrBtnSepTxt`}>OR</p>
        <p className={classes.instructionNotes}>{t('Drag and drop file here')}</p>
      </>
    );
  }

  return (
    <div className={
      `${classes.decorativeContainer} ${isDragActive ? classes.active : ''} ${props.className ? props.className : ''}`}
      {...getRootProps()}>
      <input {...getInputProps()} />
      <div className={classes.btnWrapper}>
        {innerContent}
      </div>
      <p className={classes.sizeAnotation}>{t('Max File Size: 5MB')}</p>
      {
        fileRejectionItems && fileRejectionItems.length > 0 &&
        <Paper className={`my-4 ${classes.fileRejectContainer}`} elevation={0} variant="outlined">
          <p className="m-4">Error uploading the following {fileRejectionItems.length > 1 ? 'files' : 'file'}:</p>
          <ul className="m-4">
            {fileRejectionItems}
          </ul>
        </Paper>
      }
    </div>
  )
}
