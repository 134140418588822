import React from 'react';
import ThemeBtn from './ThemeBtn';
import { useTranslation } from "react-i18next";
import { useNavigate} from "react-router-dom";

const BackBtn = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const text = props.text || t('glossary:Back');
  const size = props.size || 'large';
  const goBack = () => {
    if (props.path) {
      navigate(props.path)
    }
    else {
      navigate(-1);
    }
  }

  let btnProps = {
    text: text,
    size: size,
    onClick: goBack
  }

  return (
    <ThemeBtn {...btnProps} />
  )
}

export default BackBtn;