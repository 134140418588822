import React from 'react';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ThemeBox from '../../components/UI/ThemeBox';
import Typography from '@mui/material/Typography';
import { checkAuth } from "../../util/Auth";
import reqClient from "../../util/reqClient";
import { ADMIN, CUSTOMERS, JOBS, APPLICATIONS } from '../../constants/paths';
import { json, useLoaderData, useSearchParams } from "react-router-dom";
import { setToast } from '../../states/dashboard';
import ThemeTable from '../../components/UI/ThemeTable';
import TableResponsiveWrapper from '../../components/UI/TableResponsiveWrapper';
import ThemePagination from '../../components/UI/ThemePagination';
import { formatDateTime } from '../../util/DateAndTime';

const JobsListing = () => {
  const data = useLoaderData();

  let [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get('page');
  const sort = searchParams.get('sort');
  const sortOrder = searchParams.get('sort_order');

  let newSearchParams = {};
  if(page) newSearchParams.page = page;
  if(sort) newSearchParams.sort = sort;
  if(sortOrder) newSearchParams.sort_order = sortOrder;

  const pageHandler = (event, number) => {
    let params = {
      ...newSearchParams
    }

    params.page = (number && !isNaN(number)) ? number : 1;
    setSearchParams(params);
  }

  const tableHead = [
    {
      accessor: 'title',
      label: 'Job Title',
      sortable: false,
      sort: (sort === 'title' ? sortOrder : '')
    },
    {
      accessor: 'cst_name',
      label: 'Customer',
      sortable: false,
      sort: (sort === 'cst_name' ? sortOrder : '')
    },
    {
      accessor: 'plan_name',
      label: 'Plan',
      sortable: false,
      sort: (sort === 'plan_name' ? sortOrder : '')
    },
    {
      accessor: 'subs_ja_count',
      label: 'Apps. within sub. period',
      sortable: false,
      sort: (sort === 'subs_ja_count' ? sortOrder : '')
    },
    {
      accessor: 'subscription_start',
      label: 'Sub. Start Date',
      sortable: false,
      sort: ''
    },
    {
      accessor: 'subscription_end',
      label: 'Sub. End Date',
      sortable: false,
      sort: ''
    },
    {
      accessor: '',
      label: '',
      sortable: false,
      sort: ''
    }
  ];

  let tableData = [];
  data.rows.forEach((row) => {
    tableData.push({
      id: row.id,
      cells: [
        row.title,
        {
          type: 'link',
          url: `/${ADMIN}/${CUSTOMERS}/${row.user_id}`,
          label: `${row.first_name} ${row.last_name}`
        },
        (row.plan_name ? row.plan_name : '-NA-'),
        (row.subs_ja_count ? row.subs_ja_count : '0'),
        (row.subscription_start_time ? formatDateTime(row.subscription_start_time) : '-NA-'),
        (row.subscription_end_time ? formatDateTime(row.subscription_end_time) : '-NA-'),
        {
          type: 'btn',
          url: `/${ADMIN}/${CUSTOMERS}/${row.user_id}/${JOBS}/${row.id}/${APPLICATIONS}`,
          label: "Manage Applications"
        }
      ],
      standOutIndices: [0, 1]
    })
  });

  return (
    <ThemeBox>
      <Row className="px-2 mt-2">
        <Col xl={6} lg={4}>
          <Typography 
            variant="h4"
            className="mb-3"
            sx={{ fontSize: '2rem', fontWeight: 500 }}
            >Jobs</Typography>
        </Col>
        <Col xl={3} lg={4}>
          
        </Col>
        <Col xl={3} lg={4}>
          {/* user filter */}
        </Col>
      </Row>
      <Row className="px-2 mt-2">
        <Col md={12} className="my-3">
          <TableResponsiveWrapper>
            <ThemeTable 
              head={tableHead} 
              tableData={tableData} 
              //sortHandler={sortHandler}
            />
          </TableResponsiveWrapper>
        </Col>
      </Row>
      <Row className="my-3 justify-content-center">
        <Col></Col>
        <Col sm={'auto'}>
          {data.totalPages > 0 && 
          <ThemePagination count={data.totalPages} onChange={pageHandler} page={page} />}
        </Col>
        <Col></Col>
      </Row>
    </ThemeBox>
  )
}

export default JobsListing;

export const loader = async ({request, params}) => {
  if (!checkAuth()) { return null; }

  try {
    let urlSplit = request.url.split('?');

    const response = await reqClient('admin')
      .get(`/job-details${urlSplit[1] ? '?' + urlSplit[1] : ''}`);

    const resData = response.data;

    if (resData.data?.rows) {
      return resData.data;
    }
    else {
      setToast("error", 
        resData.message ? resData.message : "Something went wrong! Please try again later.",
        true
      );
    }
  }
  catch(error) {
    let errMessage = "Unexpected error!";
    if(error.response?.data?.message) {
      errMessage = error.response.data.message;
    }

    throw json(
      {
        message: errMessage,
        role: 'admin'
      },
      { status: (error.response?.status || 500) }
    );
  }

  return null;
}