import AccountDetails from "../../components/accountDetails/AccountDetails";
import Body from "../../components/body/Body";
//import Checkouts from "../../components/checkouts/Checkouts";
import Header from "../../components/header/Header";
//import ProgressBar from "../../components/progressBar/ProgressBar";

export default function MyAccount() {
  return (
    <>
      <Header></Header>
      <Body children={<AccountDetails></AccountDetails>}></Body>
    </>
  );
}
