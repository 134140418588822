import React from 'react';
import Col from "react-bootstrap/Col";
import classes from './DashBodyWrapper.module.scss';

const DashBodyWrapper = (props) => {
  return (
    <Col className={classes.dashBodyWrapper}>
      {props.children}
    </Col>
  )
}

export default DashBodyWrapper;
