import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import classes from './PolicyPages.module.scss';
import Divider from '../../components/divider/Divider';

const TermsOfService = () => {
  return (
    <Row className='g-0'>
      <Col lg={1}></Col>
      <Col lg={10}>
        <div className={`${classes.tsWrapper} mb-4`}>
          <Divider className='mt-0' />
          <h1 className='themeH1'>JobApply24 Terms of Service</h1>
          <p className={`${classes.para} my-3`}>Effective Date: August 15th 2023</p>
          <p className={`${classes.para} my-3`}>Welcome to JobApply24, a job application service designed to simplify and automate your job search. Before using our services,
            please read these Terms of Service ("Terms") carefully. By accessing or using JobApply24, you agree to be bound by these Terms.</p>
          <p className='dashSectionHead my-3'>1. Definitions</p>
          <ul className='my-3 ms-2'>
            <li className={`${classes.listItem} mb-2`}>"We", "us", "our", or "JobApply24" refers to the owners and operators of the JobApply24 service.</li>
            <li className={`${classes.listItem} mb-2`}>"You" refers to the user or viewer of our platform.</li>
            <li className={`${classes.listItem} mb-2`}>"Services" refers to the functionality and offerings provided by JobApply24.</li>
          </ul>

          <p className='dashSectionHead my-3'>2. Services and Obligations</p>
          <ul className='my-3 ms-2'>
            <li className={`${classes.listItem} mb-2`}>We provide a platform that automates job applications to job positions that 
              match user’s preferences. We do not guarantee job placement or interviews.</li>
            <li className={`${classes.listItem} mb-2`}>We aim to maintain accuracy on our job search and applications but cannot guarantee the job ads we apply to are up-to-date or 
              that the job matches the work conditions, pay, or role advertised.</li>
          </ul>

          <p className='dashSectionHead my-3'>3. Eligibility</p>
          <ul className='my-3 ms-2'>
            <li className={`${classes.listItem} mb-2`}>By using our services, you confirm that you are at least 18 years old or of legal age in your respective country.</li>
            <li className={`${classes.listItem} mb-2`}>You must have the right to work in the country you want to apply for. (Work permit).</li>
          </ul>

          <p className='dashSectionHead my-3'>4. Data Protection and Privacy</p>
          <ul className='my-3 ms-2'>
            <li className={`${classes.listItem} mb-2`}>Your use of JobApply24 is also governed by our Privacy Policy, which is designed in compliance with the General Data Protection Regulation (GDPR).</li>
            <li className={`${classes.listItem} mb-2`}>We ensure the confidentiality of your data and will not share it with third parties without your explicit consent, except where required by law.</li>
          </ul>

          <p className='dashSectionHead my-3'>5. Limitations and Prohibitions</p>
          <ul className='my-3 ms-2'>
            <li className={`${classes.listItem} mb-2`}>Do not misuse our services. Specifically, do not interfere with our services, try to access them using a method other than the interface and instructions provided by us.</li>
            <li className={`${classes.listItem} mb-2`}>Using the platform for any unlawful purposes is strictly prohibited.</li>
          </ul>

          <p className='dashSectionHead my-3'>6. Termination and Suspension</p>
          <ul className='my-3 ms-2'>
            <li className={`${classes.listItem} mb-2`}>We reserve the right to suspend or terminate your access to our services 
              for any reason, including breach of these Terms.</li>
          </ul>

          <p className='dashSectionHead my-3'>7. Disclaimers and Limitation of Liability</p>
          <ul className='my-3 ms-2'>
            <li className={`${classes.listItem} mb-2`}>The services are provided "as is" without any warranties, either express or implied.</li>
            <li className={`${classes.listItem} mb-2`}>In no event will JobApply24 be liable for any loss or damage, including without limitation, 
              indirect or consequential loss or damage, or any loss or damage arising from loss of data or profits arising out of or in connection with the use of JobApply24.</li>
          </ul>

          <p className='dashSectionHead my-3'>8. Governing Law</p>
          <ul className='my-3 ms-2'>
            <li className={`${classes.listItem} mb-2`}>These Terms shall be governed by and construed in accordance with the laws of the European Union. Any disputes will be 
              subject to the exclusive jurisdiction of the courts of the EU.</li>
          </ul>

          <p className='dashSectionHead my-3'>9. Modifications to Terms</p>
          <ul className='my-3 ms-2'>
            <li className={`${classes.listItem} mb-2`}>We reserve the right to change these Terms at any time. Changes will be effective immediately upon posting. 
              Your continued use of the services signifies your acceptance of the revised terms.</li>
          </ul>

          <p className='dashSectionHead my-3'>10. Contact</p>
          <ul className='my-3 ms-2'>
            <li className={`${classes.listItem} mb-2`}>For any queries related to these Terms or the services, please contact us at support@jobapply24.com</li>
          </ul>

          <p className={`${classes.para} my-3`}>By registering or using our services, you agree to abide by these Terms. If you do not agree with any part of these Terms, please do not use our platform.</p>
        </div>
      </Col>
      <Col lg={1}></Col>
    </Row>
  )
}

export default TermsOfService