import { forwardRef, useState, useEffect } from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { dashboardState } from '../../states/dashboard';
import { useSnapshot } from "valtio";

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Toast = () => {
  const dashSnap = useSnapshot(dashboardState);

  const message = dashSnap.toast.message;
  const alertType = dashSnap.toast.type;
  const refCount = dashSnap.toast.refCount;
  const [open, setOpen] = useState(dashSnap.toast.open);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      console.log('Toast handle close: clickaway')
      return;
    }

    console.log('Toast handle close: not clickaway');
    setOpen(false);
  };

  useEffect(() => {
    if (message != "") {
      setOpen(true);
    }

  }, [refCount]);

  return (
    <Snackbar 
      open={open} 
      autoHideDuration={6000} 
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <Alert key={refCount} onClose={handleClose} severity={alertType} sx={{ width: '100%' }}>
        {message}
      </Alert>
    </Snackbar>
  );
}

export default Toast;