import classes from "./SummaryList.module.scss";
import SummaryListItem from "./SummaryListItem";

export default function SummaryList(props) {
  return (
    <div className={`${classes.gridContainer}`}>
      {props.items.map((item, index) => (
        <SummaryListItem
          key={index}
          item={item}
        ></SummaryListItem>
      ))}
    </div>
  );
}
