import { redirect, json } from "react-router-dom";
import axios from "axios";
import { ADMIN } from "../constants/paths";

export function setAccessToken(token) {
  localStorage.setItem('accToken', token);

  const expiration = new Date();
  expiration.setHours(expiration.getHours() + 1);
  localStorage.setItem('accExpire', expiration.toISOString());
}

export function getAccessToken() {
  const token = localStorage.getItem('accToken');
  return token;
}

export function getTokenDuration() {
  const storedExpiration = localStorage.getItem('accExpire');
  if (!storedExpiration) {
    return -1;
  }

  const expiration = new Date(storedExpiration);
  const now = new Date();

  return (expiration.getTime() - now.getTime());
}

export function tokenLoader() {
  return getAccessToken();
}

export function checkAuth() {
  const token = getAccessToken();
  return !token ? false : true;
}

export const currentUser = () => {
  const storedUser = localStorage.getItem('currentUser');
  return storedUser ? (JSON.parse(storedUser))['user'] : null;
}

export function isLoggedIn() {
  const isLoggedIn = checkAuth();
  const tokenDuration = getTokenDuration();
  const user = currentUser();

  if (!isLoggedIn || tokenDuration < 0 || !user) {
    return false;
  }
  else {
    return true;
  }
}

export function checkCustomerAuthLoader() {
  const loginStatus = isLoggedIn();
  const user = currentUser();

  if (!loginStatus) {
    return redirect('/login');
  }

  if (user && user.role !== 'customer') {
    throw json(
      {
        message: "You don't have permission to access this page.",
        role: user.role
      },
      { status: 401 }
    );
  }

  return null;
}

export function checkAdminAuthLoader() {
  const loginStatus = isLoggedIn();
  const user = currentUser();

  if (!loginStatus) {
    return redirect(`/${ADMIN}/login`);
  }

  if (user && user.role !== 'admin') {
    throw json(
      {
        message: "You don't have permission to access this page.",
        role: user.role
      },
      { status: 401 }
    );
  }

  return null;
}

export const getCurrentUser = async (token, role = 'customer') => {
  let apiPrefix = '';
  if (role === 'customer') {
    apiPrefix = process.env.REACT_APP_API_USER_PREFIX;
  }
  else if (role === 'admin') {
    apiPrefix = process.env.REACT_APP_API_ADMIN_PREFIX;
  }

  const profileParams = [
    'first_name',
    'last_name',
    'email'
  ].map((n, index) => `items[${index}]=${n}`).join('&');
  const profile =  await axios({
    url: `${process.env.REACT_APP_API_BASE_URL}/${apiPrefix}/profile?${profileParams}`,
    method: 'get',
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });

  const profData = profile.data;
  if(profData && profData.data?.user){
    return profData.data.user;
  }

  return false;
}