import React from 'react';
import DetailsForm from "../../components/jobDetails/DetailsForm";
import reqClient from "../../util/reqClient";
import { JOB_DETAILS } from "../../constants/paths";
import { setToast } from '../../states/dashboard';
import { redirect, useLoaderData } from 'react-router-dom';
import { jdSteps } from '../../states/dashboard';
import { checkAuth } from "../../util/Auth";

export default function CreateJobDetails() {
  const data = useLoaderData();

  jdSteps.active = 0;
  
  return (
    <DetailsForm
      data={data}
      method="post"
    />
  )
}

export const loader = async () => {
  if (!checkAuth()) { return null; }

  try {
    const response = await reqClient()
      .get(`/${JOB_DETAILS}/newJdData`);

    const resData = response.data;

    if (resData?.data) {
      return resData.data;
    }
    else {
      setToast("error", 
        resData.message ? resData.message : "Something went wrong! Please try again later.",
        true
      );
      return null;
    }
  }
  catch(error) {
    let errMessage = "Unexpected error!";
    if(error.response?.data?.message) {
      errMessage = error.response.data.message;
    }

    setToast("error", errMessage, true);
    return null;
  }
}

export const action = async ({request, params}) => {

  // reset the toaster
  //setToast("success", "", false);

  const formData = await request.formData();

  let postData = {
    title: formData.get('title'),
    intrTitles: formData.getAll('intrTitles[]'),
    country: formData.get('country'),
    language: formData.get('language'),
    intrCountries: formData.getAll('intrCountries[]'),
    intrLanguages: formData.getAll('intrLanguages[]')
  }

  // console.log('postData', postData);return null;

  let jdId;

  try {
    let response;
    if (request.method == "POST") {
      response = await reqClient()
        .post(`/${JOB_DETAILS}/create`, postData);
    }
    else if(request.method == "PUT") {
      jdId = params.jdId;
      response = await reqClient()
        .put(`/${JOB_DETAILS}/${params.jdId}/edit`, postData);
    } 

    if(response.data.type === 'failed') {
      setToast(
        "error", 
        response.data.message ? response.data.message : "Error saving data. Please try again later.",
        true
      );
      return null;
    }

    const resData = response.data;
    if (resData?.data?.jobDetail) {
      
      jdId = resData.data.jobDetail.id;
    }

    return redirect(`/${JOB_DETAILS}/${jdId}/exclusions`);
  }
  catch (error) {
    if ((error.response?.status == 422)) {
      // if (error.response?.data?.message) {
      //   setToast("error", error.response?.data?.message, true);
      // }
      return error.response;
    }

    let errMessage = "Unexpected error!";
    if(error.response.data?.message) {
      errMessage = error.response.data?.message;
    }

    setToast("error", errMessage, true);
  }

  return null;
}
