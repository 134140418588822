import { useState, useEffect } from "react";
import classes from "./ChangePassword.module.scss";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { BsEye } from "react-icons/bs";
import { BsEyeSlash } from "react-icons/bs";
import ThemeBtn from "../UI/ThemeBtn";
import { Form as RouterForm, useActionData } from 'react-router-dom';
import { useTranslation } from "react-i18next";

const ChangePassword = () => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const [pwdConfirmVisible, setPwdConfirmVisible] = useState(false);
  const togglePwdConfirmVisibility = () => {
    setPwdConfirmVisible(!pwdConfirmVisible);
  };

  const { t } = useTranslation();
  const actionData = useActionData();

  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const clearInputs = () => {
    setPassword('');
    setNewPassword('');
    setConfirmPassword('');
  }

  useEffect(() =>{
    if (actionData?.type === 'success') {
      clearInputs();
    }
  }, [actionData]);

  const inputErrMsg = (message) => {
    return (
      <Form.Control.Feedback type="invalid">
        {message}
      </Form.Control.Feedback>
    );
  }

  return (
    <div className={classes.wrapper}>
      <h2 className={classes.title}>{t('Change Password')}</h2>
      <RouterForm method="put">
        <div className={classes.gridContainer}>
          <input type="hidden" name="formType" value="changePassword" />
          <Form.Group className="mb-3" >
            <Form.Label>{t('Current password', { ns: 'glossary' })}</Form.Label>
            <Form.Control 
              type="password"
              name="oldPassword"
              placeholder={t('Current password', { ns: 'glossary' })}
              isInvalid={actionData && actionData.errors?.oldPassword}
              value={password}
              onChange={ (e) => setPassword(e.target.value) }
            />
            { actionData && actionData.errors?.oldPassword && 
              inputErrMsg(actionData.errors?.oldPassword)
            }
          </Form.Group>
          <Form.Group className="mb-4" >
            <Form.Label>{t('New password', { ns: 'glossary' })}</Form.Label>
            <InputGroup>
              <Form.Control
                type={passwordVisible ? "text" : "password"}
                placeholder={t('New password', { ns: 'glossary' })}
                name="newPassword"
                style={{ backgroundColor: "white", borderRight: "none" }}
                isInvalid={actionData && actionData.errors?.newPassword}
                value={newPassword}
                onChange={ (e) => setNewPassword(e.target.value) }
              />
              <InputGroup.Text
                id="basic-addon1"
                style={{ backgroundColor: "white" }}
                className="pr-5"
                onClick={togglePasswordVisibility}
              >
                {passwordVisible ? <BsEye /> : <BsEyeSlash />}
              </InputGroup.Text>
              { actionData && actionData.errors?.newPassword && 
                inputErrMsg(actionData.errors?.newPassword)
              }
            </InputGroup>
          </Form.Group>
          <Form.Group className="mb-4" >
            <Form.Label>{t('Confirm new password', { ns: 'glossary' })}</Form.Label>
            <InputGroup>
              <Form.Control
                type={pwdConfirmVisible ? "text" : "password"}
                placeholder={t('Confirm new password', { ns: 'glossary' })}
                name="confirmPassword"
                style={{ backgroundColor: "white", borderRight: "none" }}
                isInvalid={actionData && actionData.errors?.confirmPassword}
                value={confirmPassword}
                onChange={ (e) => setConfirmPassword(e.target.value) }
              />
              <InputGroup.Text
                id="basic-addon1"
                style={{ backgroundColor: "white" }}
                className="pr-5"
                onClick={togglePwdConfirmVisibility}
              >
                {pwdConfirmVisible ? <BsEye /> : <BsEyeSlash />}
              </InputGroup.Text>
              { actionData && actionData.errors?.confirmPassword && 
                inputErrMsg(actionData.errors?.confirmPassword)
              }
            </InputGroup>
          </Form.Group>
        </div>
        <div className={`${classes.btnWrapper} mt-4 mb-3`}>
          <ThemeBtn text={t('Update', { ns: 'glossary' })} variant="primary" type="submit"/>
        </div>
      </RouterForm>
    </div>
  )
}

export default ChangePassword;