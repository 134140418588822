import { useState } from 'react';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ThemeBox from '../../components/UI/ThemeBox';
import Typography from '@mui/material/Typography';
import { checkAuth } from "../../util/Auth";
import reqClient from "../../util/reqClient";
import { json, useLoaderData, useSearchParams, useNavigate, useParams, useSubmit } from "react-router-dom";
import { setToast } from '../../states/dashboard';
import ThemeTable from '../../components/UI/ThemeTable';
import TableResponsiveWrapper from '../../components/UI/TableResponsiveWrapper';
import ThemePagination from '../../components/UI/ThemePagination';
import { formatDateTime } from '../../util/DateAndTime';
import ThemeBtn from '../../components/UI/ThemeBtn';
import { ADMIN, JOBS, APPLICATIONS, CUSTOMERS } from '../../constants/paths';
import ConfirmModal from '../../components/UI/ConfirmModal';
import BackBtn from '../../components/UI/BackBtn';
import CstDetails from '../../components/customers/Details';

const JobApps = () => {
  const loaderData = useLoaderData();
  const data = loaderData?.data;
  const customer = loaderData?.customer;

  const navigate = useNavigate();
  const { cId, jdId } = useParams();
  const submit = useSubmit();

  const [showConfirm, setShowConfirm] = useState(false);
  const [delApp, setDelApp] = useState(null);

  let [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get('page');
  const sort = searchParams.get('sort');
  const sortOrder = searchParams.get('sort_order');

  let newSearchParams = {};
  if(page) newSearchParams.page = page;
  if(sort) newSearchParams.sort = sort;
  if(sortOrder) newSearchParams.sort_order = sortOrder;

  const toCreateApp = () => {
    navigate(`/${ADMIN}/${CUSTOMERS}/${cId}/${JOBS}/${jdId}/${APPLICATIONS}/create`)
  }

  const pageHandler = (event, number) => {
    let params = {
      ...newSearchParams
    }

    params.page = (number && !isNaN(number)) ? number : 1;
    setSearchParams(params);
  }

  const initiateDelete = (id) => {
    setDelApp(id);
    setShowConfirm(true);
  }

  const handleConfirmDelete = (value = false, data = null) => {

    setShowConfirm(false);
    if (value === true) {
      let formData = new FormData();
      formData.append('delApp', data);

      submit(formData, {
        action: `/${ADMIN}/${CUSTOMERS}/${cId}/${JOBS}/${jdId}/${APPLICATIONS}`,
        method: "delete"
      });
    }
    else {
      return;
    }
  }

  const tableHead = [
    {
      accessor: 'position',
      label: 'Job Position',
      sortable: true,
      sort: (sort === 'position' ? sortOrder : '')
    },
    {
      accessor: 'company',
      label: 'Company',
      sortable: true,
      sort: (sort === 'company' ? sortOrder : '')
    },
    {
      accessor: 'created',
      label: 'Created At',
      sortable: true,
      sort: (sort === 'created' ? sortOrder : '')
    },
    {
      accessor: '',
      label: '',
      sortable: false,
      sort: ''
    },
    {
      accessor: '',
      label: '',
      sortable: false,
      sort: ''
    }
  ];

  let tableData = [];
  data.rows.forEach((row) => {
    tableData.push({
      id: row.id,
      cells: [
        row.position,
        row.company,
        formatDateTime(row.created_at),
        {
          type: 'link',
          url: `/${ADMIN}/${CUSTOMERS}/${cId}/${JOBS}/${row.job_detail_id}/${APPLICATIONS}/${row.id}/edit`,
          label: `Edit`
        },
        {
          type: 'delete',
          onClick: () => { initiateDelete(row.id) },
          label: `Delete`
        }
      ],
      standOutIndices: [0, 1]
    })
  });

  return (
    <Row>
      <Col lg={12} md={12}>
        <div className='d-flex justify-content-start mb-3'>
          <BackBtn size="small" text="Back to list" path={`/${ADMIN}/${JOBS}`} />
        </div>
      </Col>
      <Col lg={6} md={12}>
        <CstDetails data={customer} />
      </Col>
      <Col lg={6} md={12}>
        <ThemeBox>
          <Row className="px-2 mt-2">
            <Col>
              <div class="d-flex flex-wrap justify-content-between mb-2">
                <Typography 
                  variant="h4"
                  className="mb-3"
                  sx={{ fontSize: '2rem', fontWeight: 500 }}
                  >Job Applications</Typography>
                <div>
                  <ThemeBtn 
                    text="New Application" 
                    size="small" 
                    variant="primary" 
                    onClick={toCreateApp}
                    className="ms-3"
                    />
                </div>
              </div>
            </Col>
          </Row>
          <Row className="px-2 mt-2">
            <Col md={12} className="my-3">
              <TableResponsiveWrapper>
                <ThemeTable 
                  head={tableHead} 
                  tableData={tableData} 
                  //sortHandler={sortHandler}
                />
              </TableResponsiveWrapper>
            </Col>
          </Row>
          <Row className="my-3 justify-content-center">
            <Col></Col>
            <Col sm={'auto'}>
              {data.totalPages > 0 && 
              <ThemePagination count={data.totalPages} onChange={pageHandler} page={page} />}
            </Col>
            <Col></Col>
          </Row>
          <ConfirmModal 
            key="cnfrm_del_app" 
            message="Are you sure you want to delete this application?"
            show={showConfirm}
            handleAction={handleConfirmDelete}
            data={delApp}
          />
        </ThemeBox>
      </Col>
    </Row>
  )
}

export default JobApps;

export const loader = async ({request, params}) => {
  if (!checkAuth()) { return null; }

  const jdId = params.jdId;
  const cId = params.cId;

  try {
    let urlSplit = request.url.split('?');

    // const response = await reqClient('admin')
    //   .get(`/job-details/${jdId}/applications${urlSplit[1] ? '?' + urlSplit[1] : ''}`);

    const [response, customer] = await Promise.all([
      reqClient('admin').get(`/job-details/${jdId}/applications${urlSplit[1] ? '?' + urlSplit[1] : ''}`),
      reqClient('admin').get(`/${CUSTOMERS}/${cId}`)
    ])

    const resData = response.data;
    const cstData = customer.data;

    if (resData.data?.rows) {
      return {
        data: resData.data,
        customer: cstData.data
      }
    }
    else {
      setToast("error", 
        resData.message ? resData.message : "Something went wrong! Please try again later.",
        true
      );
    }
  }
  catch(error) {
    let errMessage = "Unexpected error!";
    if(error.response?.data?.message) {
      errMessage = error.response.data.message;
    }

    throw json(
      {
        message: errMessage,
        role: 'admin'
      },
      { status: (error.response?.status || 500) }
    );
  }

  return null;
}

export const action = async ({request, params}) => {
  const formData = await request.formData();

  try {
    if (request.method === 'DELETE') {
      const delApp = formData.get('delApp');

      const response = await reqClient('admin').delete(
        `/job-details/${params.jdId}/application/${delApp}/delete`
      );

      if(response.data?.type === 'failed') {
        setToast(
          "error", 
          response.data?.message ? response.data?.message : "Error deleting the record. Please try again later.",
          true
        );
        return null;
      }

      setToast("success", "Successfully deleted!", true);
      return null;
    }
  }
  catch (error) {
    let errMessage = "Unexpected error!";
    if(error.response?.data?.message) {
      errMessage = error.response.data.message;
    }

    throw json(
      {
        message: errMessage,
        role: 'admin'
      },
      { status: (error.response?.status || 500) }
    );
  }
}