import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { checkAuth } from "../../util/Auth";
import currentUserState from "../../states/currentUser";
import reqClient from "../../util/reqClient";
import { setToast } from '../../states/dashboard';
import { redirect, useLoaderData, Link, useNavigate } from "react-router-dom";
import { JOB_DETAILS, APPLIED_JOBS, SUBSCRIPTION } from "../../constants/paths";
import ThemeBox from '../../components/UI/ThemeBox';
import StepTitle from "../../components/jobDetails/StepTitle";
import { useTranslation } from "react-i18next";
import ThemeOutlinedCard from "../../components/UI/ThemeOutlinedCard";
import classes from './Landing.module.scss';
import ThemeBtn from "../../components/UI/ThemeBtn";
import ThemeCircularStat from "../../components/UI/ThemeCircularStat";
import { formatDateTime } from "../../util/DateAndTime";
import JobPreferences from "../../components/jobDetails/JobPreferences";

const Landing = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const loaderData = useLoaderData();
  const summary = loaderData?.summary;

  let currentPlan = null;
  if (summary?.plans && loaderData?.subscription) {
    summary.plans.forEach((plan) => {
      if (plan.id === loaderData.subscription?.pricing_plan_id) {
        currentPlan = plan;
      }
    })
  }

  const handleManageSubscr = async () => {
    try {
      const response = await reqClient()
        .post(`/${SUBSCRIPTION}/billing-portal-session?return_page=dashboard`);
      
        const resData = response.data;
    
        if (resData.type === 'success') {
          window.location.href = resData.data.sessionUrl;
        }
    }
    catch (error) {
      console.log(error);
    }
  }

  return (
    <ThemeBox>
      <Row className="g-0">
        <Col sm={12} className={`pb-2`}>
          <StepTitle className="mb-3 text-center text-md-start px-2 pb-3" title={t("Welcome to JobApply 24")} />
        </Col>
        <Col sm={12} className={`pb-2 mb-5`}>
          <Row className="g-0">
            <Col lg={5} xl={4}>
              <div className={classes.appStatContainer}>
                <Row className="g-0">
                  <Col className="px-2 mb-4" sm={12} md={6}>
                    <ThemeCircularStat count={loaderData.totalApps ? loaderData.totalApps : "0"} label={t("Total applications sent")} />
                  </Col>
                  <Col className="px-2 mb-4" sm={12} md={6}>
                    <ThemeCircularStat count={loaderData.totalAppsCurrentMonth ? loaderData.totalAppsCurrentMonth : "0"} label={t("Sent This Month")} />
                  </Col>
                </Row>
                <div className="d-flex justify-content-center py-3 mt-2">
                  <ThemeBtn 
                    text={t("See Applied Jobs")} 
                    variant="primary"
                    onClick={() => { navigate(`/${APPLIED_JOBS}`) }}
                  />
                </div>
              </div>
            </Col>
            <Col lg={7} xl={8}>
              <div className="d-flex flex-column ps-lg-4 pe-lg-2 px-md-2">
                <h2 className="dashSectionHead mb-3">{t("glossary:Subscription")}</h2>
                <ThemeOutlinedCard className="p-4">
                  {loaderData?.subscription &&
                  <Row className="g-0">
                    <Col xl={9} md={6} sm={12}>
                      <p className="dashHelperText mb-2">{t("My Plan")}</p>
                      <p className="dashSectionSubHead mb-2">{currentPlan?.label}</p>
                      <ul className="mb-2">
                        {
                          currentPlan?.features.map((f, i) => (
                            <li className="dashHelperText" key={i}>{f}</li>
                          ))
                        }
                      </ul>
                    </Col>
                    <Col xl={3} md={6} sm={12}>
                      <ThemeBtn 
                        text={t('Manage Subscription')}
                        size="small"
                        onClick={() => { handleManageSubscr(); }}
                      />
                      <p className="dashHelperText my-2">{t("Subscription renews")}: {formatDateTime(loaderData?.subscription.end_time)}</p>
                    </Col>
                  </Row>
                  }

                  {!loaderData?.subscription &&  
                  <Row className="g-0">
                    <Col>
                      <p className="dashHelperText">{t("You don't have any subscription. Please visit the job preferences page")}</p>
                      <Link to={`/${JOB_DETAILS}/${summary?.id}/edit`} style={{fontSize: '14px'}}>{t("Go to preferences")}</Link>
                    </Col>
                  </Row>
                  }                
                  
                </ThemeOutlinedCard>
              </div>
            </Col>
          </Row>
        </Col>
        <Col className={`p-2`} sm={12}>
          <h3 className="dashSectionHead mb-3">{t("Job Preferences")}</h3>
          <JobPreferences summary={summary} />
          <div className="d-flex justify-content-lg-end justify-content-center py-3 mt-3">
            <ThemeBtn 
              text={t('Edit Preferences')}
              variant="primary"
              onClick={() => { navigate(`/${JOB_DETAILS}/${summary?.id}/edit`) }}
            />
          </div>
        </Col>
      </Row>
    </ThemeBox>
  );
}

export default Landing;

export async function loader() {
  if (!checkAuth()) { return null; }

  const { user: currentUser } = currentUserState;
  
  try {
    const response = await reqClient()
      .get(`/${JOB_DETAILS}/index`);

    const resData = response.data;

    if (resData.data?.count == 0) {
      console.log('Redirect to the create job details page.');
      return redirect(`/${JOB_DETAILS}/create`);
    }
    else {
      console.log('Stay in dashboard, list the jobs');
    }

    return resData.data;
  }
  catch(error) {
    setToast("error", "Something went wrong! Please try again later.", true);
    return null;
  }
}

