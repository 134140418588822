import { useState } from "react";
import { jdSteps } from '../../states/dashboard';
import StepTitle from "../../components/jobDetails/StepTitle";
import FileUploadContainer from "../../components/UI/FileUploadContainer";
import ContentWrapper from '../../components/jobDetails/ContentWrapper';
import ThemeBtn from "../../components/UI/ThemeBtn";
import { BsArrowRight } from "react-icons/bs";
import { JOB_DETAILS } from "../../constants/paths";
import { checkAuth } from "../../util/Auth";
import { setToast } from '../../states/dashboard';
import reqClient from "../../util/reqClient";
import { useLoaderData, useParams, useSubmit, useNavigate } from "react-router-dom";
import BoxSubHeading from "../../components/UI/BoxSubHeading";
import UploadedItem from "../../components/UI/UploadedItem";
import ConfirmModal from "../../components/UI/ConfirmModal";
import { useTranslation } from "react-i18next";

export default function Documents() {
  jdSteps.active = 2;

  const documents = useLoaderData();
  const { jdId } = useParams();
  const submit = useSubmit();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [showConfirm, setShowConfirm] = useState(false);
  const [delDoc, setDelDoc] = useState();

  const uploadHandler = async (files) => {
    if (files.length < 1) {
      return false;
    }

    let formData = new FormData();
    files.forEach(file => {
      formData.append('upload', file);
    });

    submit(formData, {
      action: `/${JOB_DETAILS}/${jdId}/documents`,
      method: "post",
      encType: 'multipart/form-data'
    });
  }

  const viewDocument = async (id) => {

    try {
      const response = await reqClient()
        .get(`/${JOB_DETAILS}/${jdId}/documents/${id}`);
  
      const resData = response.data;
  
      if (resData.data?.url) {
        //window.open(resData.data.url, '_blank', 'noreferrer');
        window.location.replace(resData.data.url)
      }
      else {
        setToast("error", 
          resData.message ? resData.message : "Something went wrong! Please try again later.",
          true
        );
      }
    }
    catch(error) {
      let errMessage = "Unexpected error!";
      if(error.response?.data?.message) {
        errMessage = error.response.data.message;
      }
  
      setToast("error", errMessage, true);
    }
  }

  const handleConfirmAction = (value = false, data = null) => {
    setShowConfirm(false);

    if (value === true) {
      let formData = new FormData();
      formData.append('delDoc', data);

      submit(formData, {
        action: `/${JOB_DETAILS}/${jdId}/documents`,
        method: "delete"
      });
    }
    else {
      return;
    }
  }

  const initiateDeleteDoc = (param) => {
    setDelDoc(param);
    setShowConfirm(true);
  }

  const moveToNextStep = () => {
    navigate(`/${JOB_DETAILS}/${jdId}/pricing`);
  }

  return (
    <>
      <ContentWrapper>
        <StepTitle className="mt-4" title={t('Upload Documents')} />
        <p className="my-4">{ t('Upload docs description') }</p>
        <FileUploadContainer
          dropHandler={uploadHandler} 
          className="mt-3"
          type="upload_docs"
          dzOptions={{
            noClick: false,
            noKeyboard: true,
            multiple: true
          }}
          extensions={['pdf', 'jpg', 'jpeg', 'png']}
        />

        { documents && documents.length > 0 &&
          <BoxSubHeading text="Documents" />
        }

        { documents && documents.length > 0 &&
           documents.map(document => {
            return <UploadedItem 
              key={document.id} 
              itemId={document.id} 
              name={document.name} 
              className="mb-4"
              viewItemHandler={viewDocument}
              delItemHandler={initiateDeleteDoc}
            />
          })
        }

      </ContentWrapper>
      <div className="d-flex justify-content-end">
        <ThemeBtn text="Back" type="button" className="me-3" 
          onClick={ () => { navigate(`/${JOB_DETAILS}/${jdId}/generate-cv`); } } />
        <ThemeBtn text="Next" type="button" variant="primary" onClick={moveToNextStep} ></ThemeBtn>
      </div>
      <ConfirmModal 
        key="cnfrm_del_doc" 
        message={t("Are you sure you want to delete this document?")}
        show={showConfirm}
        handleAction={handleConfirmAction}
        data={delDoc}
      />
    </>
  )
}

export const loader = async ({params}) => {
  if (!checkAuth()) { return null; }

  try {
    const response = await reqClient()
      .get(`/${JOB_DETAILS}/${params.jdId}/documents`);

    const resData = response.data;

    if (resData.data?.documents) {
      return resData.data.documents;
    }
    else {
      setToast("error", 
        resData.message ? resData.message : "Something went wrong! Please try again later.",
        true
      );
      return null;
    }
  }
  catch(error) {
    let errMessage = "Unexpected error!";
    if(error.response?.data?.message) {
      errMessage = error.response.data.message;
    }

    setToast("error", errMessage, true);
    return null;
  }
}

export const action = async ({request, params}) => {
  const formData = await request.formData();

  try {
    if (request.method === 'POST') {
      const response = await reqClient().post(
        `/${JOB_DETAILS}/${params.jdId}/documents/upload`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        }
      );

      if(response.data.status === 'failed') {
        setToast(
          "error", 
          response.data.message ? response.data.message : "Error saving data. Please try again later.",
          true
        );
        return null;
      }

      setToast("success", "Successfully uploaded!", true);
      return null;
    }
    else if (request.method === 'DELETE') {
      const delDoc = formData.get('delDoc');

      const response = await reqClient().delete(
        `/${JOB_DETAILS}/${params.jdId}/documents/${delDoc}`
      );

      if(response.data.status === 'failed') {
        setToast(
          "error", 
          response.data.message ? response.data.message : "Error deleting file. Please try again later.",
          true
        );
        return null;
      }

      setToast("success", "Successfully deleted!", true);
      return null;
    }
  }
  catch(error) {
    let errMessage = "Unexpected error!";
    if(error.response?.data?.message) {
      errMessage = error.response.data.message;
    }

    setToast("error", errMessage, true);
    return null;
  }

  return null;
}
