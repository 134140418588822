import * as PATHS from '../constants/paths';
import AuthRootLayout from '../pages/auth/Root';
import AdminLogin, { action as LoginAction} from '../pages/auth/AdminLogin';
import { checkAdminAuthLoader } from '../util/Auth';

import AdminDashLayout from '../pages/admin/Root';
import Customers, { loader as CustomerLoader } from '../pages/admin/Customers';
import Dashboard from '../pages/admin/Dashboard';
import JobApps, { loader as JobAppsLoader, action as JobAppsAction } from '../pages/admin/JobApps';
import JobsListing, { loader as JobsLoader } from '../pages/admin/JobsListing';
import CustomerDetails, { loader as CstDetailsLoader } from '../pages/admin/CustomerDetails';
import CreateJobApp, {loader as CreateAppLoader, action as CreateAppAction } from '../pages/admin/CreateJobApp';
import EditJobApp, { loader as EditAppLoader, action as UpdateAppAction } from '../pages/admin/EditJobApp';

import ForgotPassword, { action as ForgotPwdAction } from '../pages/auth/ForgotPassword';
import ResetPassword, { loader as ResetPwdLoader, action as ResetPwdAction } from '../pages/auth/ResetPassword';

/*const adminRouter = {
  path: PATHS.ADMIN,
  element: <Admin />,
  children: [
    { 
      path: PATHS.LOGIN,
      element: <AuthRootLayout />,
      children: [
        {
          index: true,
          element: <AdminLogin />,
          action: LoginAction
        }
      ]
    },

    {
      index: true,
      element: <AdminDashLayout />,
      loader: checkAdminAuthLoader
    }
  ]
}*/

const adminRouter = [
  {
    path: `${PATHS.ADMIN}/${PATHS.LOGIN}`,
    element: <AuthRootLayout />,
    exact: true,
    children: [
      {
        index: true,
        element: <AdminLogin />,
        action: LoginAction
      }
    ]
  },

  {
    path: `${PATHS.ADMIN}`,
    element: <AuthRootLayout />,
    exact: true,
    children: [
      { 
        path: `${PATHS.FORGOT_PWD}`,
        element: <ForgotPassword />,
        action: ForgotPwdAction
      },
      { 
        path: `${PATHS.PWD_RESET}`,
        element: <ResetPassword />,
        loader: ResetPwdLoader,
        action: ResetPwdAction
      }
    ]
  },

  {
    path: PATHS.ADMIN,
    element: <AdminDashLayout />,
    loader: checkAdminAuthLoader,
    children: [
      {
        index: true,
        element: <Dashboard />,
      },
      {
        path: PATHS.CUSTOMERS,
        element: <Customers />,
        loader: CustomerLoader
      },
      {
        path: `${PATHS.CUSTOMERS}/:cId`,
        element: <CustomerDetails />,
        loader: CstDetailsLoader
      },
      {
        path: PATHS.JOBS,
        element: <JobsListing />,
        loader: JobsLoader
      },
      {
        path: `${PATHS.CUSTOMERS}/:cId/${PATHS.JOBS}/:jdId/${PATHS.APPLICATIONS}`,
        element: <JobApps />,
        loader: JobAppsLoader,
        action: JobAppsAction
      },
      {
        path: `${PATHS.CUSTOMERS}/:cId/${PATHS.JOBS}/:jdId/${PATHS.APPLICATIONS}/create`,
        element: <CreateJobApp />,
        loader: CreateAppLoader,
        action: CreateAppAction
      },
      {
        path: `${PATHS.CUSTOMERS}/:cId/${PATHS.JOBS}/:jdId/${PATHS.APPLICATIONS}/:jaId/edit`,
        element: <EditJobApp />,
        loader: EditAppLoader,
        action: UpdateAppAction
      }
    ]
  }
  
];

export default adminRouter;