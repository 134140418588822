import React, { useState } from "react";
import classes from "./accountDetails.module.scss";
import {
  Badge,
  Avatar,
  imageListClasses,
  Stack,
  Divider,
  useMediaQuery,
} from "@mui/material";
import avatarImg from "../../assets/images/big_logo.jpg";
import penImg from "../../assets/icons/Pen.svg";
import Form from "react-bootstrap/Form";
import { Button, InputGroup } from "react-bootstrap";
import { BsEye } from "react-icons/bs";
import { BsEyeSlash } from "react-icons/bs";
import Document from "./document/document";

export default function AccountDetails() {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const isSmallerScreen = useMediaQuery("(max-width:992px)");
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <div className={classes.wrapper}>
      <h2 className={classes.title}>My Account</h2>
      <div className={classes.avatarWrapper}>
        <img className={classes.avatar} src={avatarImg} alt="avatarImg" />
        <div className={classes.avatarBtnWrapper}>
          <a href="#">
            <img className={classes.avatarBtn} src={penImg} alt="penImg" />
          </a>
        </div>
      </div>
      <Form>
        <div className={classes.gridContainer}>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>First name</Form.Label>
            <Form.Control type="text" placeholder="Andrei" />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Second name</Form.Label>
            <Form.Control type="text" placeholder="Krai" />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Email </Form.Label>
            <Form.Control type="email" placeholder="name@example.com" />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Job title</Form.Label>
            <Form.Control type="text" placeholder="UIUX Designer" />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Mobile number</Form.Label>
            <Form.Control type="text" placeholder="+91 98652 52398" />
          </Form.Group>
          <Form.Group className="mb-4" controlId="exampleForm.ControlInput1">
            <Form.Label>Password</Form.Label>
            <InputGroup>
              <Form.Control
                type={passwordVisible ? "text" : "password"}
                placeholder="Password"
                style={{ backgroundColor: "white", borderRight: "none" }}
              />
              <InputGroup.Text
                id="basic-addon1"
                style={{ backgroundColor: "white" }}
                className="pr-5"
                onClick={togglePasswordVisibility}
              >
                {passwordVisible ? <BsEye /> : <BsEyeSlash />}
              </InputGroup.Text>
            </InputGroup>
          </Form.Group>
        </div>
      </Form>
      <div className={classes.documentContainer}>
        <Stack
          direction={isSmallerScreen ? "column" : "row"}
          spacing={2}
          divider={<Divider orientation="vertical" flexItem />}
        >
          <Document
            title={"Resume"}
            file={{ name: "Resume.pdf", type: "pdf" }}
          ></Document>
          <Document
            title={"Documents"}
            file={{ name: "Elections card.txt" }}
          ></Document>
          <Document
            title={"Current active plan"}
            text={"Golden Plan"}
          ></Document>
        </Stack>
      </div>
      <div className={classes.btnWrapper}>
        <Button className={classes.btn} variant="primary">
          Save Now
        </Button>
      </div>
    </div>
  );
}
