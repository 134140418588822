import { useState } from 'react';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ThemeBox from '../../components/UI/ThemeBox';
import Typography from '@mui/material/Typography';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { BsSearch } from "react-icons/bs";
import ThemeTable from '../../components/UI/ThemeTable';
import TableResponsiveWrapper from '../../components/UI/TableResponsiveWrapper';
import { checkAuth } from "../../util/Auth";
import reqClient from "../../util/reqClient";
import { APPLIED_JOBS } from '../../constants/paths';
import { json, useLoaderData, useSearchParams } from "react-router-dom";
import { setToast } from '../../states/dashboard';
import ThemePagination from '../../components/UI/ThemePagination';
import { useTranslation } from "react-i18next";
import { formatDateTime } from '../../util/DateAndTime';

const AppliedJobs = () => {
  const [typingTimeout, setTypingTimeout] = useState(0);

  const data = useLoaderData();
  console.log('Loader Data', data);
  const { t } = useTranslation();

  let [searchParams, setSearchParams] = useSearchParams();
  const search = searchParams.get('search');
  const page = searchParams.get('page');
  const sort = searchParams.get('sort');
  const sortOrder = searchParams.get('sort_order');
  const filter = searchParams.get('filter');

  let newSearchParams = {};
  if(search) newSearchParams.search = search;
  if(filter) newSearchParams.filter = filter;
  if(sort) newSearchParams.sort = sort;
  if(sortOrder) newSearchParams.sort_order = sortOrder;
  if(page) newSearchParams.page = page;

  const searchHandler = (e) => {
    let value = e.target.value;

    if (typingTimeout) {
      clearTimeout(typingTimeout)
    }

    setTypingTimeout(setTimeout(() => {
      let params = {
        ...newSearchParams
      }
  
      if(value) {
        params.search = value;
      }
      else {
        if (params.hasOwnProperty('search')) delete params['search'];
      }

      setSearchParams(params);
    }, 1500))
  }

  const sortHandler = (col, order) => {
    //console.log('sortHandler', [col, order]);
    let params = {
      ...newSearchParams
    }

    if (order) {
      params.sort = col;
      params.sort_order = order;
    }
    else {
      if (params.hasOwnProperty('sort')) delete params['sort'];
      if (params.hasOwnProperty('sort_order')) delete params['sort_order'];
    }

    setSearchParams(params);
  }

  const pageHandler = (event, number) => {
    let params = {
      ...newSearchParams
    }

    params.page = (number && !isNaN(number)) ? number : 1;
    setSearchParams(params);
  }

  const tableHead = [
    {
      accessor: 'applied_at',
      label: `${ t('glossary:Date') }`,
      sortable: true,
      sort: (sort === 'applied_at' ? sortOrder : '')
    },
    {
      accessor: 'job_title',
      label: `${ t('Job title') }`,
      sortable: false,
      sort: (sort === 'job_title' ? sortOrder : '')
    },
    {
      accessor: 'position',
      label: `${ t('Position applied for') }`,
      sortable: true,
      sort: (sort === 'position' ? sortOrder : '')
    },
    {
      accessor: 'company',
      label: `${ t('glossary:Company') }`,
      sortable: true,
      sort: (sort === 'company' ? sortOrder : '')
    },
    {
      accessor: '',
      label: '',
      sortable: false,
      sort: ''
    }
  ];

  let tableData = [];
  data.rows.forEach((row) => {
    tableData.push({
      id: row.id,
      cells: [
        row.applied_at ? formatDateTime(row.applied_at) : '-NA-',
        row.job_title, row.position, row.company,
        {
          type: 'btn',
          url: `/${APPLIED_JOBS}/${row.id}`,
          label: `${ t('View details') }`
        }
      ],
      standOutIndices: [2]
    })
  });

  return (
    <ThemeBox>
      <Row className="px-2 mt-2">
        <Col xl={9} lg={8}>
          <Typography 
            variant="h4"
            className="mb-3"
            sx={{ fontSize: '2rem', fontWeight: 500 }}
            >{ t('My applied jobs') }</Typography>
        </Col>
        <Col xl={3} lg={4}>
          <InputGroup className="mb-3">
            <InputGroup.Text className="themeInpGrpText">
              <BsSearch />
            </InputGroup.Text>
            <Form.Control
              className="inpVariant1 brd-l-0"
              type="text"
              name="search"
              placeholder={ t('glossary:Search') }
              defaultValue={(search || '')}
              onChange={searchHandler}
            />
          </InputGroup>
        </Col>
      </Row>
      <Row className="px-2 mt-2">
        <Col md={12} className="my-3">
          <TableResponsiveWrapper>
            <ThemeTable 
              head={tableHead} 
              tableData={tableData} 
              sortHandler={sortHandler}
            />
          </TableResponsiveWrapper>
        </Col>
      </Row>
      <Row className="my-3 justify-content-center">
        <Col></Col>
        <Col sm={'auto'}>
          {data.totalPages > 0 && 
          <ThemePagination count={data.totalPages} onChange={pageHandler} page={page} />}
        </Col>
        <Col></Col>
      </Row>
    </ThemeBox>
  )
}

export default AppliedJobs;

export const loader = async ({request, params}) => {
  if (!checkAuth()) { return null; }

  try {
    let urlSplit = request.url.split('?');

    const response = await reqClient()
      .get(`/${APPLIED_JOBS}${urlSplit[1] ? '?' + urlSplit[1] : ''}`);

    const resData = response.data;

    if (resData.data?.rows) {
      return resData.data;
    }
    else {
      setToast("error", 
        resData.message ? resData.message : "Something went wrong! Please try again later.",
        true
      );
    }
  }
  catch(error) {
    let errMessage = "Unexpected error!";
    if(error.response?.data?.message) {
      errMessage = error.response.data.message;
    }

    throw json(
      {
        message: errMessage,
        role: 'admin'
      },
      { status: (error.response?.status || 500) }
    );
  }

  return null;
}