export const HOME = "/";
export const DASHBOARD = "dashboard";
export const JOB_DETAILS = "job-details";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const REGISTER = "signup";
export const SUBSCRIPTION = "subscription";
export const PROFILE = "profile";
export const ADMIN = "admin";
export const CUSTOMERS = "customers";
export const JOBS = "jobs";
export const JOB_APPS = "job-applications";
export const APPLICATIONS = "applications";
export const APPLIED_JOBS = "applied-jobs";
export const FORGOT_PWD = "forgot-password";
export const PWD_RESET = "password/reset";