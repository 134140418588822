import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import LogoDark from "../../assets/logo-dark.svg";
import classes from "./RightHeader.module.scss";
import { Link } from "react-router-dom";

const RightHeader = ({ title, btn, url }) => {
  return (
    <Row className={classes.rightHeadWrapper}>
      <Col xs={12} md={4} lg={12}>
        <img
          className={classes.logo}
          src={LogoDark}
          style={{ display: "none" }}
          alt="logo"
        />
      </Col>
      <Col xs={12} md={8} lg={12} className="my-auto">
        <p className={`${classes.signupText} my-auto`}>
          {title} <Link to={url}>{btn}</Link>
        </p>
      </Col>
    </Row>
  );
};

export default RightHeader;
