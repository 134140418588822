import Button from "react-bootstrap/Button";
import classes from "./ThemeBtn.module.scss";

export default function ThemeBtn(props) {
  const text = props.text;
  const iconPlacement = props.iconPlacement ? props.iconPlacement : 'end';

  let inputProps = {
    variant: props.variant ? props.variant : "outline-primary",
    type: props.type ? props.type : "button",
    disabled: props.disabled ? true : false
  }

  if(props.onClick) {
    inputProps.onClick = props.onClick
  }

  return (
    <Button
      className={`${classes.thmBtn} ${props.size === 'small' ? classes.tbSmall : ''} ${inputProps.variant === 'primary' ? classes.clrWhite : ''}
        ${props.className ? props.className : ''}`}
      {...inputProps}
    >
      {iconPlacement === 'end' && 
        <>
          {props.text}
          {props.children && 
            <span className={classes.btnIcon}>{props.children}</span>
          }
        </>
      }
      {iconPlacement === 'start' && 
        <>
          {props.children && 
            <span className={classes.btnIcon}>{props.children}</span>
          }
          {props.text}
        </>
      }
    </Button>
  );
}
