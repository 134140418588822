import classes from "./SummaryListItem.module.scss";
import penImg from "../../assets/icons/Pen.svg";
import pdfFile from "../../assets/images/pdf_file.jpg";
import { FaRegFileAlt, FaRegFilePdf, FaRegFileImage } from "react-icons/fa";
import { Link } from "react-router-dom";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useTranslation } from "react-i18next";

export default function SummaryListItem({ item }) {
  const { t } = useTranslation();

  const getFileIcon = (fileName) => {
    let fileIcon = '';

    if (fileName) {
      let ext = fileName.split('.').pop();
      if (ext.toLowerCase() === 'pdf') {
        fileIcon = <FaRegFilePdf className={classes.img} />;
      }
      else if(['jpg', 'jpeg', 'png'].includes(ext.toLowerCase())) {
        fileIcon = <FaRegFileImage className={classes.img} />;
      }
      else {
        fileIcon = <FaRegFileAlt className={classes.img} />;
      }
    }

    return fileIcon;
  }

  let cardContent;

  if (item.type === 'job_details') {
    cardContent = (
      <Row className="g-0">
        <Col xxl={6}>
          <Row className="g-0">
            <Col md={12}>
              <p className={classes.title}>
                {item.data?.title}
              </p>
            </Col>
            {item.data?.intrTitles.length > 0 &&
            <Col md={12}>
              <p className={`${classes.subTitle} mb-2`}>{t("I'm also interested in:")}</p>
              {
                item.data?.intrTitles.map((item, i) => (
                  <p className={`${classes.infoText} mb-2`} key={i}>{item.title}</p>
                ))
              }
            </Col>}
          </Row>
        </Col>
        <Col xxl={6}>
          <Row className="g-0">
            <Col md={12}>
              <p className={`${classes.subTitle} mb-2`}>{t("glossary:Locations")}:</p>
              <div className="d-flex flex-wrap mb-2">
                {item.data?.country && <span className={`${classes.infoText} ${classes.hglt} pe-2`} 
                  key={item.data?.country?.id}>{item.data?.country?.name}</span> }
                {
                  item.data?.intrCountries.map((item) => (
                    <span className={`${classes.infoText} pe-2`} key={item.id}>{item.name}</span>
                  ))
                }
              </div>
            </Col>
            <Col md={12}>
              <p className={`${classes.subTitle} mb-2`}>{t("glossary:Languages")}:</p>
              <div className="d-flex flex-wrap mb-2">
                {item.data?.language && <span className={`${classes.infoText} ${classes.hglt} pe-2`} 
                  key={item.data?.language?.id}>{item.data?.language?.name}</span> }
                {
                  item.data?.intrLanguages.map((item) => (
                    <span className={`${classes.infoText} pe-2`} key={item.id}>{item.name}</span>
                  ))
                }
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
  else if (item.type === 'exclusions') {
    cardContent = (
      <Row className="g-0">
        <Col xxl={6}>
          <Row className="g-0">
            <Col md={12}>
              <p className={`${classes.subTitle} mb-2`}>{t("Don't apply to these companies")}:</p>
              {
                item.data?.exCompanies.map((item, i) => (
                  <p className={`${classes.infoText} mb-2`} key={i}>{item.name}</p>
                ))
              }
              {item.data?.exCompanies?.length < 1 &&
              <p className={`${classes.infoText} mb-2`}>-NA-</p>}
            </Col>
            <Col md={12}>
              <p className={`${classes.subTitle} mb-2`}>{t("Don't apply to these job types")}:</p>
              {
                item.data?.exTypes.map((item, i) => (
                  <p className={`${classes.infoText} mb-2`} key={i}>{item.type}</p>
                ))
              }
              {item.data?.exTypes?.length < 1 &&
              <p className={`${classes.infoText} mb-2`}>-NA-</p>}
            </Col>
          </Row>
        </Col>
        <Col xxl={6}>
          <Row className="g-0">
            <Col md={12}>
              <p className={`${classes.subTitle} mb-2`}>{t("Don't apply to these job posts (url)")}:</p>
              {
                item.data?.exUrls.map((item, i) => (
                  <p className={`${classes.infoText} mb-2`} key={i}>{item.url}</p>
                ))
              }
              {item.data?.exUrls?.length < 1 &&
              <p className={`${classes.infoText} mb-2`}>-NA-</p>}
            </Col>
            <Col md={12}>
              <p className={`${classes.subTitle} mb-2`}>{t("Anything else we should know?")}:</p>
              {item.data?.exDesc && 
              <p className={`${classes.infoText} mb-2`}>{item.data.exDesc.length > 100 ? item.data.exDesc.substring(0, 100)+ ' ...': item.data.exDesc}</p>}
              {!item.data?.exDesc &&
              <p className={`${classes.infoText} mb-2`}>-NA-</p>}
            </Col>
          </Row>
        </Col>
      </Row>
    )
  }
  else if (item.type === 'uploads') {
    cardContent = (
      <Row className="g-0">
        {
          item.data.map((fileName) => (
            <Col xxl={6}>
              <div className={`${classes.fileContainer} pb-2 pt-1`}>
                {getFileIcon(fileName)}
                <p className={classes.fileName}>{fileName}</p>
              </div>
            </Col>
          ))
        }
      </Row>
    )
  }

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <div className={classes.stepContainer}>
          <p className={classes.stepNumber}>{item.title}</p>
        </div>
        {
          item.editLink &&
          <Link to={item.editLink}><img className={classes.penImg} src={penImg} alt="penImg" /></Link>
        }
      </div>
      {/* <p className={classes.stepName}>{item.subTitle ? item.subTitle : ''}</p> */}

      { (cardContent || '') }

      {item.mainText && (
        <p className={classes.text}>
          {item.mainText}
        </p>
      )}
      {item.subText && (
        <p className={classes.subText}>
          {item.subText}
        </p>
      )}
      {/*item.file && (
        <div className={classes.fileContainer}>
          {fileIcon}
          <p className={classes.fileName}>{item.file}</p>
        </div>
      )*/}
      {item.listItems && (
        <ul className={classes.list}>
          {item.listItems.map((value) => { return <li>{value}</li> })}
        </ul>
      )}
    </div>
  );
}
