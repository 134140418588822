export const formatDateTime = (isoString, time = false) => {
  const date = new Date(isoString);
  let options = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric"
  };

  if (time) {
    options.hour = "numeric";
    options.minute = "numeric";
    options.hour12 = true;
  }

  return date.toLocaleString(undefined, options);
}
