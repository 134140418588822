import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import classes from "./Root.module.scss";
import Logo from "../../assets/logo-light.svg";
import BgImg from "../../assets/images/login-bg.jpeg";

import { Outlet } from "react-router-dom";
import Toast from "../../components/UI/toast";

export default function RootLayout() {
  return (
    <Container fluid className={classes.mainWrapper}>
      <Row className="h-100">
        <Col
          className={`${classes.sectionLeft}`}
          style={{
            background: `linear-gradient(0deg, rgba(6, 22, 69, 0.9), rgba(6, 22, 69, 0.9)), url(${BgImg})`,
            backgroundSize: `cover`,
          }}
        >
          <Row>
            <Col className={classes.logoWrapper}>
              <img className={classes.logo} src={Logo} alt="logo" />
            </Col>
          </Row>
        </Col>
        <Col>
          <Outlet />
          <Toast />
        </Col>
      </Row>
    </Container>
  );
}
