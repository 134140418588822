import { useState, useEffect } from "react";
import classes from "./ManagePlan.module.scss";
import {
  Stack,
  Divider,
  useMediaQuery,
} from "@mui/material";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import ThemeBtn from "../UI/ThemeBtn";
import { Form as RouterForm, useActionData } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import ThemeBox from "../UI/ThemeBox";
import infoImg from "../../assets/icons/info.png";
import reqClient from "../../util/reqClient";
import { SUBSCRIPTION } from "../../constants/paths";

const ManagePlan = () => {
  const isSmallerScreen = useMediaQuery("(max-width:992px)");
  const { t } = useTranslation();
  const [hasSubscription, setHasSubscription] = useState(false);
  const [planDetails, setPlanDetails] = useState({}); 

  useEffect(() => {
    const getPlans = async () => {
      try {
        const response = await reqClient()
          .get(`/get-pricing-plans`);
  
        const resData = response.data;
    
        if (resData.type === 'success') {
          //return resData.data;
          if (resData.data.subscription) {
            setHasSubscription(true);
          }

          if (resData.data.activePlanId) {
            let plans = resData.data.plans;
            let currentPlan;

            for (const i in plans) {
              if (plans[i].id === resData.data.activePlanId) {
                currentPlan = plans[i];
              }
            }

            setPlanDetails(currentPlan);
          }
        }
      }
      catch (error) {
        console.log(error);
      }
    };

    getPlans();
    
  }, [])

  const handleManageSubscr = async () => {
    try {
      const response = await reqClient()
        .post(`/${SUBSCRIPTION}/billing-portal-session`);
      
        const resData = response.data;
    
        if (resData.type === 'success') {
          window.location.href = resData.data.sessionUrl;
        }
    }
    catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      { hasSubscription &&
      <ThemeBox>
        <div className={classes.wrapper}>
          <h2 className={classes.title}>{t('glossary:Subscription')}</h2>

          <div className={classes.innerContainer}>
            <Stack
              direction={isSmallerScreen ? "column" : "row"}
              spacing={isSmallerScreen ? 2 : 4}
              justifyContent="center"
              alignItems="center"
              divider={<Divider orientation="vertical" flexItem />}
              sx={{
                alignItems: 'flex-start',
                justifyContent: 'space-between'
              }}
            >
              <div>
                <p className={`${classes.sectionTitle} mb-3`}>{t('My Plan')}</p>
                <p className={classes.sectionText}>
                  {planDetails.title}
                  {/* <OverlayTrigger
                    key="top"
                    placement="top"
                    overlay={
                      <Tooltip id={`tooltip-current-plan`}>
                        <ul>
                          { planDetails.features.map((item, i) => <li key={i}>{item}</li>) }
                        </ul>
                      </Tooltip>
                    }
                  >
                    <img className={classes.infoImg} src={infoImg} alt="infoImg" />
                  </OverlayTrigger> */}
                </p>
                <ul className="mt-2">
                  <li>{`${planDetails.currency} ${planDetails.price}/${t('glossary:month')}`}</li>
                  { planDetails.features.map((item, i) => <li key={i}>{item}</li>) }
                </ul>
              </div>
              <div>
                <ThemeBtn text={t('Manage Subscription')} onClick={handleManageSubscr} size="small" />
              </div>
            </Stack>
          </div>
        </div>
      </ThemeBox>
      }
    </>
  )
}

export default ManagePlan;