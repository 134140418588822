import React, { useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { Form, InputGroup } from "react-bootstrap";
import Divider from "../../components/divider/Divider";
import LinkedinSVG from "../../assets/icons/linkedin.svg";
import RightHeader from "../../components/auth/RightHeader";
import MainHeading from "../../components/auth/MainHeading";
import classes from "./Register.module.scss";
import { BsEye } from "react-icons/bs";
import { BsEyeSlash } from "react-icons/bs";
import { Form as ReactRouterForm, useActionData, redirect } from "react-router-dom";
import axios from "axios";
import { setAccessToken } from '../../util/Auth';
import { setToast } from '../../states/dashboard';
import { getCurrentUser } from '../../util/Auth';
import currentUserState from "../../states/currentUser";
import { DASHBOARD } from "../../constants/paths";
import { useTranslation } from "react-i18next";

export default function Register() {
  const { t } = useTranslation();
  const [passwordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const actionResponse = useActionData();
  const data = actionResponse?.data;

  return (
    <>
      <RightHeader
        title={`Already have an account?`}
        btn={"Login"}
        url={"/login"}
      />
      <MainHeading title={"Create Account"} />
      <Row md={12}>
        <Col className={classes.formWrapper}>
          <ReactRouterForm method='post' noValidate>
            <Form.Group className="mb-4" controlId="firstName">
              <Form.Label>{t('First name', { ns: 'glossary' })}</Form.Label>
              <Form.Control 
                type="text"
                name="firstName" 
                placeholder={t('First name', { ns: 'glossary' })}
                isInvalid={data && data.errors?.firstName}
              />
              {data && data.errors?.firstName &&
                <Form.Control.Feedback type="invalid">
                  {data.errors?.firstName}
                </Form.Control.Feedback>
              }
            </Form.Group>
            <Form.Group className="mb-4" controlId="lastName">
              <Form.Label>{t('Last name', { ns: 'glossary' })}</Form.Label>
              <Form.Control 
                type="text"
                name="lastName" 
                placeholder={t('Last name', { ns: 'glossary' })}
                isInvalid={data && data.errors?.lastName}
              />
              {data && data.errors?.lastName &&
                <Form.Control.Feedback type="invalid">
                  {data.errors?.lastName}
                </Form.Control.Feedback>
              }
            </Form.Group>

            <Form.Group className="mb-4" controlId="email">
              <Form.Label>{t('email address', { ns: 'common' })}</Form.Label>
              <Form.Control 
                type="email"
                name="email"
                placeholder={t('email address', { ns: 'common' })} 
                isInvalid={data && data.errors?.email}
              />
              {data && data.errors?.email &&
                <Form.Control.Feedback type="invalid">
                  {data.errors?.email}
                </Form.Control.Feedback>
              }
            </Form.Group>

            <Form.Group className="mb-4" controlId="password">
              <Form.Label>{t('password', { ns: 'common' })}</Form.Label>
              <InputGroup hasValidation={(data && data.errors?.password) ? true: false}>
                <Form.Control
                  type={passwordVisible ? "text" : "password"}
                  placeholder={t('password', { ns: 'common' })}
                  name="password"
                  style={{ backgroundColor: "white", borderRight: "none" }}
                  isInvalid={data && data.errors?.password}
                />
                <InputGroup.Text
                  style={{ backgroundColor: "white" }}
                  className="pr-5"
                  onClick={togglePasswordVisibility}
                >
                  {passwordVisible ? <BsEye /> : <BsEyeSlash />}
                </InputGroup.Text>
                {data && data.errors?.password &&
                  <Form.Control.Feedback type="invalid">
                    {data.errors?.password}
                  </Form.Control.Feedback>
                }
              </InputGroup>
            </Form.Group>
            <div className="d-grid gap-2 mt-5">
              <Button variant="primary" className={`themeBtnFilled`} type="submit">
                Register
              </Button>
            </div>

            {/* <Divider text="Or" /> */}

            {/* <div className="d-grid gap-2 mt-4">
              <Button  className={classes.signupLinkedinBtn}>
                <img src={LinkedinSVG} alt="LinkedIn" /> Register with LinkedIn
              </Button>
            </div> */}
          </ReactRouterForm>
        </Col>
      </Row>
    </>
  );
}

const action = async ({request, params}) => {
  const data = await request.formData();

  // reset the toaster
  /* setToast("success", "", false);*/

  const postData = {
    firstName: data.get('firstName'),
    lastName: data.get('lastName'),
    email: data.get('email'),
    password: data.get('password'),
    confirmPassword: data.get('password')
  }

  try {
    const response = await axios({
      url: `${process.env.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_API_USER_PREFIX}/signup`,
      method: 'post',
      data: postData
    });

    const resData = response.data;
    if (resData && resData.data?.accessToken) {
      let user = await getCurrentUser(resData.data.accessToken);
      if (user) {
        user.role = resData.data?.role;
        currentUserState.user = user;
        // finally set the access token
        setAccessToken(resData.data?.accessToken);
        return redirect(`/${DASHBOARD}`);
      }
    }
    
    setToast("error", "Something went wrong. Please try again later.", true);
    return null;
  }
  catch (error) {
    if ((error.response?.status == 422)) {
      return error.response;
    }
    
    let errMessage = "Unexpected error!";
    if(error.response.data?.message) {
      errMessage = error.response.data?.message;
    }

    setToast("error", errMessage, true);

    return null;
  }
}

export { action };