/*import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Header from "../../components/header/Header";
import { Outlet } from "react-router-dom";
import Toast from "../../components/UI/toast";
import DashBodyWrapper from "../../components/UI/DashBodyWrapper";*/
//import SideNav from "../../components/UI/SideNav";

import classes from './Root.module.scss';

import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Logo from "../../assets/logo-light.svg";
import { useSubmit, useNavigate, Link, Outlet } from "react-router-dom";
import { useSnapshot } from "valtio";
import currentUserState from "../../states/currentUser";
import { useTranslation } from "react-i18next";
import { getMenuItems } from "../../util/MenuItems";
import Toast from "../../components/UI/toast";

const RootLayout = () => {

  const submit = useSubmit();
  const { t } = useTranslation();

  const { user: currentUser } = useSnapshot(currentUserState);

  const handleLogout = () => {
    submit(null, {
      action: "/logout",
      method: "post"
    });
  }

  const mainMenu = getMenuItems(currentUser.role);
  const drawerWidth = 240;
  return (
    <Box sx={{ display: 'flex', minHeight: '100vh' }}>
      <CssBaseline />
      <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} className={classes.topNav}>
        <Toolbar className={classes.customToolbar}>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/admin"
            sx={{
              mr: 2,
              display: { md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            <img src={Logo} className={classes.logo} alt="logo" />
          </Typography>

        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
        }}
      >
        <Toolbar className={classes.customToolbar} />
        <Divider />
        <Box sx={{ overflow: 'auto', paddingTop: 2 }}>
          <List>
            {
              Object.keys(mainMenu).map((key, index) => {
                return (
                  <ListItem key={index} disablePadding>
                    <ListItemButton sx={{ fontSize: '1rem' }}>
                      <Link to={mainMenu[key].path} className={`dropdown-item`} >{t(mainMenu[key].title)}</Link>
                    </ListItemButton>
                  </ListItem>
                )
              })
            }
          </List>
          <Divider />
          <List>
            <ListItem key={'logout'} disablePadding>
              <ListItemButton onClick={handleLogout}>
                {/* <ListItemIcon>
                  {index % 2 === 0 ? 'Inb' : 'Ml'}
                </ListItemIcon> */}
                <ListItemText sx={{ fontSize: '1rem' }} primary={'Logout'} />
              </ListItemButton>
            </ListItem>
          </List>
        </Box>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 4 }} className={classes.mainBox}>
        <Toolbar className={classes.customToolbar} />
        <Outlet />
        <Toast />
      </Box>
    </Box>
  );
}

export default RootLayout;