import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useRouteError, isRouteErrorResponse } from "react-router-dom";
import { ADMIN } from '../constants/paths'

function ErrorPage() {
  const error = useRouteError();
  console.log(error);

  let heading = '500';
  let description = 'Oops! Something went wrong.';
  let backUrl = null;

  if (isRouteErrorResponse(error)) {
    heading = error.status;

    if (error.status == '404') {
      description = "Sorry, the page you’re looking for doesn’t exist.";
    }

    if (error.data?.message) {
      description = error.data?.message;
    }

    if (error.data?.role === 'customer') {
      backUrl = "/";
    }
    else if (error.data?.role === 'admin') {
      backUrl = `/${ADMIN}`;
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        minHeight: '100vh',
        backgroundColor: '#0e4686',
      }}
    >
      <Typography variant="h1" style={{ color: 'white' }}>
        { heading }
      </Typography>
      <Typography variant="h6" style={{ color: 'white' }}>
        { description }
      </Typography>
      { backUrl && <Button variant="contained" href={backUrl} style={{marginTop: '25px'}}>Back Home</Button> }
    </Box>
  )
}

export default ErrorPage;