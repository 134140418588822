import classes from "./body.module.scss";

export default function Body({ children, nextBtn }) {
  return (
    <>
      <div className={classes.section}>
        <div className={classes.wrapper}> {children}</div>
      </div>
    </>
  );
}
