import { proxy } from "valtio";

const dashboardState = proxy({
  toast: {
    type: "",
    message: "",
    open: false,
    refCount: 0 
  }
});

const setToast = (type, message, isOpen) => {
  let refCount = dashboardState.toast.refCount + 1;
  dashboardState.toast = {
    type: type,
    message: message,
    open: isOpen,
    refCount: refCount
  }
}

const jdSteps = proxy({
  active: 0,
  isSubscribed: false
});

export {
  dashboardState,
  setToast,
  jdSteps
}