import { useState, useRef } from 'react';
import classes from './Landing.module.scss';
import ThemeBtn from '../../components/UI/ThemeBtn';
import HeroBannerRight from '../../assets/images/hero_banner_right.png';

import { 
  AiOutlineStar, 
  AiOutlineBars, 
  AiOutlineHistory, 
  AiOutlineEuroCircle, 
  AiOutlineTeam,
  AiOutlineSetting
} from "react-icons/ai";

import GetStartedRight from '../../assets/images/get_started_right.png';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import StepConnector from '@mui/material/StepConnector';

import CheckCircle from '../../assets/icons/check-circle-1.svg'
import CheckCircleWhite from '../../assets/icons/check-circle-white.svg';

import { useTranslation } from "react-i18next";
import reqClient from "../../util/reqClient";
import { useLoaderData, Link, useNavigate } from "react-router-dom";
import { REGISTER } from '../../constants/paths';

const CardItem = (props) => {
  const data = props.data;
  return (
    <div className={classes.lnCard}>
      <div style={{background: data.iconBg}}>
        {data.icon}
      </div>
      <h3>{data.title}</h3>
      <p>{data.desc}</p>
    </div>
  )
}

const PlanCard = (props) => {
  const { t } = useTranslation();
  const plan = props.data;
  const navigate = useNavigate();

  const [showCheckWhite, setShowCheckWhite] = useState(false);
  const planMouseEnter = () => { setShowCheckWhite(true) };
  const planMouseLeave = () => { setShowCheckWhite(false) };

  return (
    <div className={classes.pCard} onMouseEnter={planMouseEnter} onMouseLeave={planMouseLeave}>
      <h3>{`${plan.currency} ${String(plan.price)}`}<span>/{t('glossary:month')}</span></h3>
      <h4>{plan.title}</h4>
      <ul>
        {plan.features && plan.features.map((feature, i) => {
          return (
            <li key={i}>
              <span>
                {!showCheckWhite && <img src={CheckCircle} />}
                {showCheckWhite && <img src={CheckCircleWhite} /> }
              </span>
              <p>{feature}</p>
            </li>
          )
        })}
      </ul>
      <button 
        type="button"
        onClick={ () => { navigate(`/${REGISTER}`); } }
      >{t('Get Started', {'ns': 'home'})}</button>
    </div>
  )
}

const FaqItem = (props) => {
  const [isActive, setIsActive] = useState(false);
  const toggleActive = () => {
    setIsActive((prev) => {
      return !prev;
    })
  }

  return (
    <div className={classes.faqItem}>
      <div className={classes.accHead}>
        <p>{props.qst}</p>
        <button className={`ms-2 ${classes.accBtn} ${isActive ? classes.bActive : ''}`} onClick={toggleActive}>{ isActive ? '-' : '+' }</button>
      </div>
      <div className={`${classes.accBody} ${isActive && classes.accActive}`}>
        <p>{props.ans}</p>
      </div>
    </div>
  );
}

const Landing = () => {
  const { t } = useTranslation();
  const plans = useLoaderData();
  const navigate = useNavigate();

  const cardSectionHead = useRef();

  const learnMore = t('learnMore', {'ns': 'home', 'returnObjects': true});
  const learnMoreCards = [
    {
      iconBg: 'rgba(38, 198, 218, 0.20)',
      icon: <AiOutlineStar style={{color: '#10C2D8'}} />,
      ...learnMore[0]
    },
    {
      iconBg: 'rgba(252, 87, 59, 0.20)',
      icon: <AiOutlineBars style={{color: '#FC573B'}} />,
      ...learnMore[1]
    },
    {
      iconBg: 'rgba(190, 99, 249, 0.20)',
      icon: <AiOutlineHistory style={{color: '#B84FFD'}} />,
      ...learnMore[2]
    },
    {
      iconBg: 'rgba(242, 201, 76, 0.20)',
      icon: <AiOutlineEuroCircle style={{color: '#F2C94C'}} />,
      ...learnMore[3]
    },
    {
      iconBg: 'rgba(39, 174, 96, 0.20)',
      icon: <AiOutlineTeam style={{color: '#27AE60'}} />,
      ...learnMore[4]
    },
    {
      iconBg: 'rgba(38, 198, 218, 0.20)',
      icon: <AiOutlineSetting style={{color: '#26C6DA'}} />,
      ...learnMore[5]
    }
  ];

  const getStarted = t('getStarted', {'ns': 'home', 'returnObjects': true});

  const faqs = [
    {
      qst: "Who will be applying on my behalf?",
      ans: "Ut enim ad minim veniam quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat aute irure dolor"
    },
    {
      qst: "Who will be applying on my behalf?",
      ans: "Ut enim ad minim veniam quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat aute irure dolor"
    },
    {
      qst: "Who will be applying on my behalf?",
      ans: "Ut enim ad minim veniam quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat aute irure dolor"
    }
  ];
  const faqListBreak = Math.ceil((faqs.length / 2));

  const goToRegister = () => {
    navigate(`/${REGISTER}`);
  }

  return (
    <>
      <div className={classes.heroBanner}>
        <div className={classes.left}>
          <div className={classes.leftContentWrapper}>
            <h1 className={classes.bannerHeading}>{t('Automate your job search', {'ns': 'home'})}</h1>
            <h4 className={classes.bannerText}>{t('No more scrolling through countless job posts', {'ns': 'home'})}</h4>
            <div className={classes.btnWrapper}>
              <ThemeBtn 
                text={t('Learn more', {'ns': 'home'})} 
                className={`me-2 ${classes.lmBtn}`}
                onClick={() => { cardSectionHead.current?.scrollIntoView({behavior: 'smooth'}); }}
              />
              <ThemeBtn text={t('Get Started', {'ns': 'home'})} variant="primary" onClick={goToRegister} />
            </div>
          </div>
        </div>
        <div className={classes.right} style={{ 
          backgroundImage: `url(${HeroBannerRight})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat'
        }}></div>
        <div className={classes.rightSpacing}></div>
      </div>
      <div className={classes.learnMore}>
        <h2 ref={cardSectionHead}>{t('Your job search on autopilot', {'ns': 'home'})}</h2>
        <div className={classes.cardContainer}>
          {learnMoreCards.map((cardData, i) => {
            return <CardItem key={i} data={cardData} />
          })}
        </div>
      </div>
      <div className={classes.getStarted}>
        <div className={classes.lftSection}>
          <div className={classes.gsWrapper}>
            <h2>{t('Get started in 5 simple steps', {'ns': 'home'})}</h2>
            <Stepper orientation="vertical" 
              connector={<StepConnector className="cstStepConnector" sx={{marginLeft: '18px'}} />}>
              {getStarted.map((step, index) => (
                <Step active={true} key={index}>
                  <StepLabel>
                    <h5 className={classes.stepLabel}>{step.title}</h5>
                  </StepLabel>
                  <StepContent sx={
                    index !== 4 ? 
                      { borderLeft: '2px solid var(--bs-primary)', marginLeft: '18px', paddingBottom: '15px' } 
                      : { marginLeft: '18px', paddingBottom: '15px' } 
                  }>
                    <p className={classes.stepDesc}>{step.desc}</p>
                  </StepContent>
                </Step>
              ))}
            </Stepper>
          </div>
        </div>
        <div className={classes.rtSection} style={{ 
          backgroundImage: `url(${GetStartedRight})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat'
        }}></div>
      </div>
      {plans && <div className={classes.subsPlans}>
        <h2>{t('Subscription Plans', {'ns': 'home'})}</h2>
        <div className={classes.planContainer}>
          {plans.map((plan, i) => {
            return <PlanCard data={plan} key={i} />
          })}
        </div>
      </div>
      }

      { false && <div className={classes.faq}>
        <h2>{t('FAQ', {'ns': 'home'})}</h2>
        <div className={classes.faqContainer}>
          <div className={classes.faqInner}>
            {
              faqs.map((item, i) => {
                if(i <= (faqListBreak - 1)) {
                  return <FaqItem key={i} {...item} />
                }
              })
            }
          </div>
          <div className={classes.faqInner}>
            {
              faqs.map((item, i) => {
                if(i > (faqListBreak - 1)) {
                  return <FaqItem key={i} {...item} />
                }
              })
            }
          </div>
        </div>
      </div> }
    </>
  )
}

export default Landing;

export const loader = async ({request, params}) => {

  try {

    const response = await reqClient('public')
      .get(`/pricing-plans`);

    const resData = response.data;

    if (resData.data?.plans) {
      return resData.data.plans;
    }
  }
  catch(error) {
    let errMessage = "Unexpected error!";
    if(error.response?.data?.message) {
      errMessage = error.response.data.message;
    }

    console.log(errMessage);
  }

  return null;
}