import { useState } from 'react';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ThemeBox from '../../components/UI/ThemeBox';
import Typography from '@mui/material/Typography';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { BsSearch } from "react-icons/bs";
import ThemeTable from '../../components/UI/ThemeTable';
import TableResponsiveWrapper from '../../components/UI/TableResponsiveWrapper';
import { checkAuth } from "../../util/Auth";
import reqClient from "../../util/reqClient";
import { CUSTOMERS, ADMIN } from '../../constants/paths';
import { json, useLoaderData, useSearchParams } from "react-router-dom";
import { setToast } from '../../states/dashboard';
import ThemePagination from '../../components/UI/ThemePagination';

const Customers = () => {
  //console.log('Inside customers page')
  const [typingTimeout, setTypingTimeout] = useState(0);

  const data = useLoaderData();

  let [searchParams, setSearchParams] = useSearchParams();
  const search = searchParams.get('search');
  const page = searchParams.get('page');
  const sort = searchParams.get('sort');
  const sortOrder = searchParams.get('sort_order');
  const filter = searchParams.get('filter');

  let newSearchParams = {};
  if(search) newSearchParams.search = search;
  if(filter) newSearchParams.filter = filter;
  if(sort) newSearchParams.sort = sort;
  if(sortOrder) newSearchParams.sort_order = sortOrder;
  if(page) newSearchParams.page = page;

  const filterOptions = [
    { label: 'Subscribed', value: '1' },
    { label: 'Not Subscribed', value: '2' }
  ]

  const tableHead = [
    {
      accessor: 'first_name',
      label: 'First Name',
      sortable: true,
      sort: (sort === 'first_name' ? sortOrder : '')
    },
    {
      accessor: 'last_name',
      label: 'Last Name',
      sortable: true,
      sort: (sort === 'last_name' ? sortOrder : '')
    },
    {
      accessor: 'email',
      label: 'Email',
      sortable: false,
      sort: ''
    },
    {
      accessor: 'phone',
      label: 'Phone',
      sortable: false,
      sort: ''
    },
    {
      accessor: 'is_subscribed',
      label: 'Subscribed',
      sortable: true,
      sort: (sort === 'is_subscribed' ? sortOrder : '')
    },
    {
      accessor: 'plan_name',
      label: 'Plan',
      sortable: true,
      sort: (sort === 'plan_name' ? sortOrder : '')
    },
    {
      accessor: '',
      label: '',
      sortable: false,
      sort: ''
    }
  ];

  let tableData = [];
  data.rows.forEach((row) => {
    tableData.push({
      id: row.id,
      cells: [
        row.first_name, row.last_name, row.email, (row.phone || '-NA-'),
        (row.is_subscribed ? 'Yes' : 'No'), (row.plan_name ? row.plan_name : '-NA-'),
        {
          type: 'btn',
          url: `/${ADMIN}/${CUSTOMERS}/${row.id}`,
          label: "View Details"
        }
      ],
      standOutIndices: [0, 1]
    })
  });

  //console.log('tableHead', tableHead);

  const sortHandler = (col, order) => {
    //console.log('sortHandler', [col, order]);
    let params = {
      ...newSearchParams
    }

    if (order) {
      params.sort = col;
      params.sort_order = order;
    }
    else {
      if (params.hasOwnProperty('sort')) delete params['sort'];
      if (params.hasOwnProperty('sort_order')) delete params['sort_order'];
    }

    setSearchParams(params);
  }

  const filterHandler = (e) => {
    //console.log('filterHandler', e.target.value);
    let filterValue = e.target.value;
    let params = {
      ...newSearchParams
    }

    if(filterValue) {
      params.filter = filterValue;
    }
    else {
      if (params.hasOwnProperty('filter')) delete params['filter'];
    }
    setSearchParams(params);
  }

  const searchHandler = (e) => {
    let value = e.target.value;

    if (typingTimeout) {
      clearTimeout(typingTimeout)
    }

    setTypingTimeout(setTimeout(() => {
      let params = {
        ...newSearchParams
      }
  
      if(value) {
        params.search = value;
      }
      else {
        if (params.hasOwnProperty('search')) delete params['search'];
      }

      setSearchParams(params);
    }, 1500))
  }

  const pageHandler = (event, number) => {
    let params = {
      ...newSearchParams
    }

    params.page = (number && !isNaN(number)) ? number : 1;
    setSearchParams(params);
  }

  return (
    <ThemeBox>
      <Row className="px-2 mt-2">
        <Col xl={6} lg={4}>
          <Typography 
            variant="h4"
            className="mb-3"
            sx={{ fontSize: '2rem', fontWeight: 500 }}
            >Customers</Typography>
        </Col>
        <Col xl={3} lg={4}>
          <InputGroup className="mb-3">
            <InputGroup.Text className="themeInpGrpText">
              <BsSearch />
            </InputGroup.Text>
            <Form.Control
              className="inpVariant1 brd-l-0"
              type="text"
              name="search"
              placeholder="Search"
              defaultValue={(search || '')}
              onChange={searchHandler}
            />
          </InputGroup>
        </Col>
        <Col xl={3} lg={4}>
          <Form.Select className="inpVariant1 mb-3" onChange={filterHandler} defaultValue={filter}>
            <option value="">Filter</option>
            {filterOptions.map((item, i) => {
              return <option key={i+1} value={item.value}>{item.label}</option>
            })}
          </Form.Select>
        </Col>
      </Row>
      <Row className="px-2 mt-2">
        <Col md={12} className="my-3">
          <TableResponsiveWrapper>
            <ThemeTable 
              head={tableHead} 
              tableData={tableData} 
              sortHandler={sortHandler}
            />
          </TableResponsiveWrapper>
        </Col>
      </Row>
      <Row className="my-3 justify-content-center">
        <Col></Col>
        <Col sm={'auto'}>
          {data.totalPages > 0 && 
          <ThemePagination count={data.totalPages} onChange={pageHandler} page={page} />}
        </Col>
        <Col></Col>
      </Row>
    </ThemeBox>
  )
}

export default Customers;

export const loader = async ({request, params}) => {
  if (!checkAuth()) { return null; }

  console.log('INSIDE LOADER', request);

  try {
    let urlSplit = request.url.split('?');

    const response = await reqClient('admin')
      .get(`/${CUSTOMERS}${urlSplit[1] ? '?' + urlSplit[1] : ''}`);

    const resData = response.data;

    if (resData.data?.rows) {
      return resData.data;
    }
    else {
      setToast("error", 
        resData.message ? resData.message : "Something went wrong! Please try again later.",
        true
      );
    }
  }
  catch(error) {
    let errMessage = "Unexpected error!";
    if(error.response?.data?.message) {
      errMessage = error.response.data.message;
    }

    throw json(
      {
        message: errMessage,
        role: 'admin'
      },
      { status: (error.response?.status || 500) }
    );
  }

  return null;
}
