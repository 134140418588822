import React from 'react';
import { jdSteps } from '../../states/dashboard';
import StepTitle from "../../components/jobDetails/StepTitle";
import ContentWrapper from '../../components/jobDetails/ContentWrapper';
import { useTranslation } from "react-i18next";
import { useLoaderData, useParams, Form } from "react-router-dom";
import ThemeBtn from "../../components/UI/ThemeBtn";
import reqClient from "../../util/reqClient";
import { SUBSCRIPTION, JOB_DETAILS } from "../../constants/paths";
import { setToast } from '../../states/dashboard';
import SummaryList from '../../components/jobDetails/SummaryList';
import { checkAuth } from "../../util/Auth";
import { MdInfoOutline } from "react-icons/md";

export default function Checkout() {
  jdSteps.active = 4;

  const { t } = useTranslation();
  const { jdId, planId } = useParams();
  const jdSummary = useLoaderData();

  let summaryData = [];
  if (jdSummary) {
    summaryData.push({
      title: `${t('glossary:Step')} 1 - ${t('Job Details')}`,
      //mainText: jdSummary.title,
      editLink: `/${JOB_DETAILS}/${jdId}/edit`,
      type: 'job_details',
      data: {
        id: jdSummary?.id,
        title: jdSummary?.title,
        intrTitles: jdSummary?.intrTitles,
        country: jdSummary?.country,
        language: jdSummary?.language,
        intrCountries: jdSummary?.intrCountries,
        intrLanguages: jdSummary?.intrLanguages
      }
    });

    summaryData.push({
      title: `${t('glossary:Step')} 2 - ${t('glossary:Exclusions')}`,
      editLink: `/${JOB_DETAILS}/${jdId}/exclusions`,
      type: 'exclusions',
      data: {
        exDesc: jdSummary?.exclusion_desc,
        exCompanies: jdSummary?.exCompanies,
        exTypes: jdSummary?.exTypes,
        exUrls: jdSummary?.exUrls
      }
    });

    let docList = jdSummary?.documents?.map((item) => item.name)

    let docSummary = {
      title: `${t('glossary:Step')} 3 - ${t('Upload CV & Documents')}`,
      editLink: `/${JOB_DETAILS}/${jdId}/generate-cv`,
      type: 'uploads',
      data: [
        jdSummary?.resume?.name,
        ...docList
      ]
    }

    /*if (jdSummary.documents.length === 0) {
      docSummary.subText = 'No Documents';
    }
    else if (jdSummary.documents.length === 1) {
      docSummary.file = jdSummary.documents[0].name;
    }
    else if (jdSummary.documents.length > 1) {
      docSummary.subText = `${jdSummary.documents.length} Documents`;
    }*/

    summaryData.push(docSummary);

    let currentPlan = jdSummary.plans.find(plan => plan.id == planId);
    summaryData.push({
      title: `${t('glossary:Step')} 4 - ${t('select pricing plan')}`,
      mainText: currentPlan.title,
      editLink: `/${JOB_DETAILS}/${jdId}/pricing`,
      listItems: currentPlan.features,
      type: 'pricing'
    });
  }

  return (
    <>
      <ContentWrapper>
        <StepTitle className="mt-4" title={t('checkout')} />
        <SummaryList items={summaryData} />
        <Form method="post">
          <div className="row g-0">
            <div className="col-sm"></div>
            <div className="col-sm py-4">
              <div className="d-grid gap-2">
                <ThemeBtn text={t('Subscribe now')} type="submit" variant="primary"></ThemeBtn>
                <p className='dashHelperText d-flex justify-content-center align-items-center'>
                  <MdInfoOutline style={{width: '19px', height: '19px'}} className='me-1' /><span>{t("Cancel anytime")}</span>
                </p>
              </div>
            </div>
            <div className="col-sm"></div>
          </div>
        </Form>
      </ContentWrapper>
    </>
  )
}

export const loader = async ({params}) => {
  if (!checkAuth()) { return null; }

  try {
    const response = await reqClient()
      .get(`/${JOB_DETAILS}/${params.jdId}/summary`);

    const resData = response.data;

    if (resData?.type === 'success') {
      return resData.data;
    }
    else {
      setToast("error", 
        resData.message ? resData.message : "Something went wrong! Please try again later.",
        true
      );
      return null;
    }
  }
  catch(error) {
    let errMessage = "Unexpected error!";
    if(error.response?.data?.message) {
      errMessage = error.response.data.message;
    }

    setToast("error", errMessage, true);
    return null;
  }
}

export const action = async({request, params}) => {

  try {
    const response = await reqClient()
      .post(`/${SUBSCRIPTION}/${params.planId}/create-checkout-session`);

    const resData = response.data;

    if (resData.data?.sessionUrl) {
      window.location.href = resData.data?.sessionUrl;
    }
    else {
      setToast("error", 
        resData.message ? resData.message : "Something went wrong! Please try again later.",
        true
      );
    }
  }
  catch(error) {
    let errMessage = "Unexpected error!";
    if(error.response?.data?.message) {
      errMessage = error.response.data.message;
    }

    setToast("error", errMessage, true);
  }

  return null;
}
