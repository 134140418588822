import React from 'react';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ThemeBox from '../../components/UI/ThemeBox';
import Typography from '@mui/material/Typography';
import reqClient from "../../util/reqClient";
import { setToast } from '../../states/dashboard';
import Chip from '@mui/material/Chip';
import { formatDateTime } from '../../util/DateAndTime';
import JobPreferences from '../jobDetails/JobPreferences';
import ThemeCircularStat from '../UI/ThemeCircularStat';

const Details = (props) => {
  const data = props.data;
  const summary = data.summary;

  let currentPlan = null;
  if (data?.subscription) {
    summary.plans.forEach(item => {
      if (item.id === data.subscription?.pricing_plan_id) {
        currentPlan = item;
      }
    });
  }

  const viewDocument = async (id) => {
    try {
      const response = await reqClient('admin')
        .get(`/documents/${id}/get-link`);
  
      const resData = response.data;
  
      if (resData.data?.url) {
        //window.open(resData.data.url, '_blank', 'noreferrer');
        window.location.replace(resData.data.url)
      }
      else {
        setToast("error", 
          resData.message ? resData.message : "Something went wrong! Please try again later.",
          true
        );
      }
    }
    catch(error) {
      let errMessage = "Unexpected error!";
      if(error.response?.data?.message) {
        errMessage = error.response.data.message;
      }
  
      setToast("error", errMessage, true);
    }
  }

  const viewResume = async (id) => {
    try {
      const response = await reqClient('admin')
        .get(`/resumes/${id}/get-link`);
  
      const resData = response.data;
  
      if (resData.data?.url) {
        //window.open(resData.data.url, '_blank', 'noreferrer');
        window.location.replace(resData.data.url)
      }
      else {
        setToast("error", 
          resData.message ? resData.message : "Something went wrong! Please try again later.",
          true
        );
      }
    }
    catch(error) {
      let errMessage = "Unexpected error!";
      if(error.response?.data?.message) {
        errMessage = error.response.data.message;
      }
  
      setToast("error", errMessage, true);
    }
  }

  return (
    <ThemeBox>
      {data?.customer && <Row className="px-2 mt-2">
        <Col md={12}>
          <div className="d-flex justify-content-between mb-4">
            <div>
              <Typography variant="h5" gutterBottom>
                {`${data.customer.first_name} ${data.customer.last_name}`}
              </Typography>
              <Typography variant="h6" gutterBottom sx={{ opacity: 0.5, fontSize: '1.05rem' }}>
                {data.customer.email}{data.customer.phone && ` | ${data.customer.phone}`}
              </Typography>
              <div className="d-flex mt-2">
                { !currentPlan && 
                  <Chip label="Not subscribed to any plan" variant="outlined" color="error" />
                }
                { currentPlan &&
                  <Chip label={`Subscribed to ${currentPlan.label}`} variant="outlined" color="success" />
                }
              </div>
              { data?.subscription && <p className='dashHelperText my-2'>Subscription ends: {formatDateTime(data.subscription?.end_time)}</p> }
            </div>
            <div>
              <ThemeCircularStat label="Apps. left to send" count={data.appsLeft} />
            </div>
          </div>
        </Col>
      </Row>}
      <Row className="px-2">
        <Col md={12}>
          <JobPreferences summary={summary} viewResume={viewResume} viewDocument={viewDocument} />
        </Col>
      </Row>
    </ThemeBox>
  )
}

export default Details