import React from 'react';
import ThemeBox from '../../components/UI/ThemeBox';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Link, useParams } from "react-router-dom";
import { ADMIN, CUSTOMERS } from "../../constants/paths";

const Dashboard = () => {
  return (
    <ThemeBox>
      <Row className="g-0">
        <Col className={`p-2`}>
          <Card sx={{ minWidth: 275 }} variant="outlined" style={{ textAlign: 'center' }}>
            <CardContent>
              <Typography variant="h5" component="div" sx={{ mb: 1 }}>
                Welcome Administrator!
              </Typography>
            </CardContent>
            <CardActions style={{ justifyContent: 'center' }}>
              <Link to={`/${ADMIN}/${CUSTOMERS}`} className="mb-3">Go to customer listing</Link>
            </CardActions>
          </Card>
        </Col>
      </Row>
    </ThemeBox>
  )
}

export default Dashboard;