import React from 'react';
import classes from './JobPreferences.module.scss';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import { JOB_DETAILS } from "../../constants/paths";
import { useTranslation } from "react-i18next";
import ThemeOutlinedCard from "../UI/ThemeOutlinedCard";
import PenSvg from '../../assets/icons/Pen.svg';
import ThemeFileIcon from "../UI/ThemeFileIcon";
import Divider from '@mui/material/Divider';

const JobPreferences = (props) => {
  const { t } = useTranslation();
  const summary = props.summary;
  const viewResume = props.viewResume || null;
  const viewDocument = props.viewDocument || null;

  const btmDivider = (<Divider className={classes.btDivider} sx={{borderColor: 'var(--bs-theme-card-border)', opacity: 1}} />);

  return (
    <ThemeOutlinedCard>
      <Row className={`${classes.jdWrapper} g-0 py-3`}>
        <Col lg={4} className="p-3">
          <div className="d-flex justify-content-between mb-3">
            <p className="dashHelperText">{t('Job Details')}</p>
            {summary?.editJd && 
            <Link to={`/${JOB_DETAILS}/${summary?.id}/edit`}><img src={PenSvg} alt="Edit" style={{width: '16px'}}/></Link> }
          </div>
          <p className="dashSectionSubHead mb-2" style={{fontWeight: '600'}}>{t('glossary:Role')}: {summary?.title || '-NA-'}</p>
          <p className="dashTextBold mb-2">
            <span className=" me-1">{t("I'm also interested in:")}</span>
            {(summary?.intrTitles && summary?.intrTitles.length > 0) &&
              <span className="dashHelperText">{(summary.intrTitles.map((item) => item.title)).join(', ')}</span>
            }
            {(summary?.intrTitles && summary?.intrTitles.length < 1) &&
              <span className="dashHelperText">-NA-</span>
            }
          </p>
          <p className="dashSectionSubHead mb-2" 
            style={{fontSize: '16px', fontWeight: '600'}}>{t('glossary:Location')}: {summary?.country?.name || '-NA-'}</p>
          <p className="dashTextBold mb-2">
            <span className=" me-1">{t("I'm also interested in:")}</span>
            {(summary?.intrCountries && summary?.intrCountries.length > 0) &&
              <span className="dashHelperText">{(summary.intrCountries.map((item) => item.name)).join(', ')}</span>
            }
            {(summary?.intrCountries && summary?.intrCountries.length < 1) &&
              <span className="dashHelperText">-NA-</span>
            }
          </p>
          <p className="dashSectionSubHead mb-2" 
            style={{fontSize: '16px', fontWeight: '600'}}>{t('glossary:Language')}: {summary?.language?.name || '-NA-'}</p>
          <p className="dashTextBold mb-2 pb-2">
            <span className=" me-1">{t("I'm also interested in:")}</span>
            {(summary?.intrLanguages && summary?.intrLanguages.length > 0) &&
              <span className="dashHelperText">{(summary.intrLanguages.map((item) => item.name)).join(', ')}</span>
            }
            {(summary?.intrLanguages && summary?.intrLanguages.length < 1) &&
              <span className="dashHelperText">-NA-</span>
            }
          </p>
          {btmDivider}
        </Col>
        <Col lg={4} className="p-3">
          <div className="d-flex justify-content-between mb-3">
            <p className="dashHelperText">{t('glossary:Exclusions')}</p>
            { summary?.editExcl && 
            <Link to={`/${JOB_DETAILS}/${summary?.id}/exclusions`}><img src={PenSvg} alt="Edit" style={{width: '16px'}}/></Link> }
          </div>
          <p className="dashTextBold mb-2">{t("Don't apply to these companies")}:</p>
          {(summary?.exCompanies && summary?.exCompanies.length > 0) &&
            <p className="dashHelperText mb-2">{(summary.exCompanies.map((item) => item.name)).join(', ')}</p>
          }
          {(summary?.exCompanies && summary?.exCompanies.length < 1) &&
            <p className="dashHelperText mb-2">-NA-</p>
          }
          <p className="dashTextBold mb-2">{t("Don't apply to these job types")}:</p>
          {(summary?.exTypes && summary?.exTypes.length > 0) &&
            <p className="dashHelperText mb-2">{(summary.exTypes.map((item) => item.type)).join(', ')}</p>
          }
          {(summary?.exTypes && summary?.exTypes.length < 1) &&
            <p className="dashHelperText mb-2">-NA-</p>
          }
          <p className="dashTextBold mb-2">{t("Don't apply to these job posts (url)")}:</p>
          {(summary?.exUrls && summary?.exUrls.length > 0) &&
            <p className="dashHelperText mb-2">{(summary.exUrls.map((item) => item.url)).join(', ')}</p>
          }
          {(summary?.exUrls && summary?.exUrls.length < 1) &&
            <p className="dashHelperText mb-2">-NA-</p>
          }
          <p className="dashTextBold mb-2">{t("Anything else we should know?")}:</p>
          {summary?.exclusion_desc && 
            <p className="dashHelperText mb-2 pb-2">{summary?.exclusion_desc.length > 100 ? summary?.exclusion_desc.substring(0, 100) + ' ...' : summary?.exclusion_desc }</p>
          }
          {!summary?.exclusion_desc && 
            <p className="dashHelperText mb-2 pb-2">-NA-</p>
          }
          {btmDivider}
        </Col>
        <Col lg={4} className="p-3">
          <div className="d-flex justify-content-between mb-3">
            <p className="dashHelperText">{t('glossary:Documents')}</p>
            { summary?.editDocs && 
            <Link to={`/${JOB_DETAILS}/${summary?.id}/generate-cv`}><img src={PenSvg} alt="Edit" style={{width: '16px'}}/></Link> }
          </div>
          {summary?.resume && 
          <div className="d-flex align-items-center mb-3">
            <ThemeFileIcon style={{width: '21px'}} fileName={summary?.resume?.name} />
            <p className="dashHelperText" style={{wordBreak: 'break-all'}}>
              {viewResume && <a href='#' className={classes.docLink} onClick={ () => { viewResume(summary?.resume?.id) } }>{summary?.resume?.name}</a>}
              {!viewResume && summary?.resume?.name}
            </p>
          </div>
          }
          {summary?.documents && summary?.documents.length > 0 &&
          summary?.documents.map((item) => (
            <div className="d-flex align-items-center mb-3" key={item.id}>
              <ThemeFileIcon style={{width: '21px'}} fileName={item.name} />
              <p className="dashHelperText" style={{wordBreak: 'break-all'}}>
                {viewDocument && <a href='#' className={classes.docLink} onClick={ () => { viewDocument(item.id) } }>{item.name}</a>}
                {!viewDocument && item.name}
              </p>
            </div>
          ))}
        </Col>
      </Row>
    </ThemeOutlinedCard>
  )
}

export default JobPreferences