import React from 'react'
import classes from './ThemeBox.module.scss'

export default function ThemeBox(props) {
  return (
    <div className={`p-3 ${classes.themeBox}`}>
      {props.children}
    </div>
  )
}

