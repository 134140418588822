import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Header from "../../components/header/Header";
import { Outlet } from "react-router-dom";
import Toast from "../../components/UI/toast";
import DashBodyWrapper from "../../components/UI/DashBodyWrapper";

const RootLayout = () => {
  /*return (
    <Container fluid>
      <Row>
        <Col className={`px-0`}>
          <Header />
        </Col>
      </Row>
      <Row>
        <Col className={classes.dashBodyWrapper}>
          <Outlet />
          <Toast />
        </Col>
      </Row>
    </Container>
  );*/

  return (
    <Row className="g-0">
      <Col md={12}>
        <Header />
      </Col>
      <DashBodyWrapper>
        <Outlet />
        <Toast />
      </DashBodyWrapper>
    </Row>
  );
}

export default RootLayout;