import { proxy, subscribe } from "valtio";

const storedStateString = localStorage.getItem('currentUser');
const initialState = storedStateString ? JSON.parse(storedStateString) : { user: null };

const currentUserState = proxy(initialState);

subscribe(currentUserState, () =>{
  console.log('Current user state has been changed:', currentUserState);
  localStorage.setItem('currentUser', JSON.stringify(currentUserState));
});

export default currentUserState;