import React from "react";
import classes from "./circularStepper.module.scss";
import { CircularProgress } from "@mui/material";

export default function CircularStepper({ activeStep, steps }) {
  return (
    <div className={classes.CircularProgressWrapper}>
      <div className={classes.progressWrapper}>
        <p className={classes.progressCounter}> {activeStep + 1} of 5</p>
        <CircularProgress
          variant="determinate"
          value={(activeStep + 1) * 20}
          style={{
            width: "92px",
            height: "92px",
          }}
        />
      </div>
      <div className={classes.progressNameWrapper}>
        <p className={classes.progressName}>{steps[activeStep].title}</p>

        {activeStep < 4 && (
          <p className={classes.nextProgressName}>
            Next: {steps[activeStep + 1].title}
          </p>
        )}
      </div>
    </div>
  );
}
