import React from 'react';
import ThemeBox from '../../components/UI/ThemeBox';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Link, useParams } from "react-router-dom";
import { DASHBOARD } from "../../constants/paths";
import { checkAuth } from "../../util/Auth";
import { useTranslation } from "react-i18next";

const SubscriptionStatus = () => {
  const { status } = useParams();
  const { t } = useTranslation();
  
  let content = '';

  if (status === 'success') {
    content = (
      <>
        <Typography variant="h5" component="div" sx={{ mb: 2 }}>
          {t('Subscription successful')}
        </Typography>
        <Typography sx={{ mb: 1 }} color="text.secondary">
          {t('Thank you for subscribing')}
        </Typography>
      </>
    );
  }
  else if (status === 'failed') {
    content = (
      <>
        <Typography variant="h5" component="div" sx={{ mb: 2 }}>
          {t('Oops! something went wrong')}
        </Typography>
        <Typography sx={{ mb: 1 }} color="text.secondary">
          {t('Error processing payment')}
        </Typography>
      </>
    );
  }
  else if (status === 'cancelled') {
    content = (
      <>
        <Typography variant="h5" component="div" sx={{ mb: 1 }}>
          {t('Subscription cancelled')}
        </Typography>
      </>
    );
  }


  return (
    <ThemeBox>
      <Row className="g-0">
        <Col className={`p-2`}>
          <Card sx={{ minWidth: 275 }} variant="outlined" style={{ textAlign: 'center' }}>
            <CardContent>
              {content}
            </CardContent>
            <CardActions style={{ justifyContent: 'center' }}>
              <Link to={`/${DASHBOARD}`} className="mb-3">{ `${t('Back to')} ${t('glossary:Dashboard')}` }</Link>
            </CardActions>
          </Card>
        </Col>
      </Row>
    </ThemeBox>
  )
}

export default SubscriptionStatus;