import axios from 'axios';
import { getAccessToken } from './Auth';

const reqClient = (type = 'customer') => {
  let baseUrl = process.env.REACT_APP_API_BASE_URL;
  if (type === 'customer') {
    baseUrl += `/${process.env.REACT_APP_API_USER_PREFIX}`
  }
  else if (type === 'admin') {
    baseUrl += `/${process.env.REACT_APP_API_ADMIN_PREFIX}`
  }
  else if (type === 'public') {
    baseUrl += `/${process.env.REACT_APP_API_PUBLIC_PREFIX}`
  }

  const defaultOptions = {
    baseURL: baseUrl,
    headers: {
      'Content-Type': 'application/json',
    }
  };

  // Create instance
  let instance = axios.create(defaultOptions);

  // Set the AUTH token for any request
  instance.interceptors.request.use(function (config) {
    const token = (type === 'public') ? null : getAccessToken();
    if(token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  });

  return instance;
}

export default reqClient;