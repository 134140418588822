import React from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import classes from './Divider.module.scss'

export default function Divider(props) {
  let content = (
    <Col style={{paddingLeft: '0', paddingRight: '0'}}><hr className={classes.divider} /></Col>
  );

  if (props.text) {
    content = (
      <>
        <Col style={{paddingLeft: '0'}}><hr className={classes.divider} /></Col>
        <Col className={`col-auto ${classes.dividerTextWrapper}`}><span className={classes.dividerText}>{props.text}</span></Col>
        <Col style={{paddingRight: '0'}}><hr className={classes.divider} /></Col>
      </>
    )
  }

  return (
    <Row className={classes.dividerWrapper}>
      {content}
    </Row>
  )
}
