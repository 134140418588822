import Body from "../../components/body/Body";
//import Checkouts from "../../components/checkouts/Checkouts";
//import GenerateCV from "../../components/generateCV/GenerateCV";
import Header from "../../components/header/Header";
import ProgressBar from "../../components/progressBar/ProgressBar";
//import SelectPricing from "../../components/selectPricing/SelectPricing";
//import UploadDocuments from "../../components/uploadDocuments/UploadDocuments";

export default function JobDetails() {
  return (
    <>
      <Header></Header>
      <ProgressBar></ProgressBar>
      {/* <Body children={<Checkouts />} nextBtn={true}></Body> */}
    </>
  );
}
